<template>
  <div class="courseVideoListBg ">
    <div class="courseVideomorePOst" >
      <p @click="openCourse()">更多课程</p>
    </div>
    <div class="courseVideoListPad">
      <div>
        <div class="courseVideoFlex">
          <div class="courseVideoImgWatch">
            <div>
              <img :src="courseVideoDetails.logo"/>
            </div>
            <div v-show="newUserInfo&&!newUserInfo.teacherType&&isShowBuyBtn">
              <el-button @click="freeLook()" style="width: 360px" type="primary" icon="el-icon-caret-right">免费试看</el-button>
            </div>
          </div>
          <div style="width: 68%">
            <div class="courseVideoFontFlex">
              <p>{{courseVideoDetails.name}}</p>

            </div>
            <p class="courseInstructFont">{{ courseVideoDetails.profiles }}</p>
            <div class="courseTimeChang" >
              <p class="courseTimeduration">
                <span>课程时长:</span>
                <span>{{ parseInt(courseVideoDetails.resourceDuration / 60) }}分钟</span>
              </p>
              <p class="courseTimeduration">
                <span>课程讲师:</span>
                <span v-if="courseVideoDetails.teacherUser && courseVideoDetails.teacherUser.name">{{courseVideoDetails.teacherUser.name}}</span>
              </p>
              <p class="courseTimeduration">
                <span>学习人数:</span>
                <span>{{courseVideoDetails.studyPeopleNumber}}</span>
              </p>
            </div>
            <div v-for="(item,index) in skus.slice(0,1)" :key="index" >
              <p class="courseVideoPrice" v-show="newUserInfo&&!newUserInfo.teacherType&&isShowBuyBtn">
                <span>¥</span>
<!--                <span v-show="item.courseActivityVo">{{(coursePrice*item.courseActivityVo.price).toFixed(2)}}</span>-->
                <span>{{coursePrice}}</span>
<!--                <span style="font-size: 12px;color: #409EFF;margin-left: 82px;">年度大礼包，全系列课程只需5980元</span>-->
              </p>
              <div class="courseVideoFlex">
                <template v-if="newUserInfo&&!newUserInfo.teacherType&&isShowBuyBtn">
                  <el-select v-model="courseDayNubmer" placeholder="有效天数" @change="changeCourseDay(courseDayNubmer)">
                    <el-option
                        v-for="(item1,index1) in skus"
                        :key="item1.price"
                        :label="item1.validDays+'天'"
                        :value="item1.price"
                    >
                    </el-option>
                  </el-select>
                  <button class="buyBtn" @click="postCourseBuy(item)">立即购买</button>
                </template>
                <div v-if="newUserInfo&&!newUserInfo.teacherType">
                  <div class="courseCollectBtn" v-if="isCollect==0" @click="collectCource">
                    <img src="images/collect.png"/>
                    <p>收藏</p>
                  </div>
                  <div class="courseCollectBtn" v-if="isCollect==1" @click="stdCancleCollectCource">
                    <img src="images/collects.png" alt=""/>
                    <p>已收藏</p>
                  </div>
                </div>
                <div v-if="newUserInfo&&!newUserInfo.teacherType&&isShowBuyBtn" class="courseCollectBtn" style="margin-left: 24px" @click="goShopping(courseVideoDetails)">
                  <img src="images/postCarShow2.png" v-if="!postCarShow">
                  <img src="images/postCarShow.png" v-if="postCarShow">
                  <p>购物车</p>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
<!--      标签-->
      <div style="margin-top:20px;display: flex">
        <div class="cfx">
          <div class="text">标签</div>
        </div>
        <div style="display: flex">
          <p class="courserlabel" v-for="(item,index) in labels" :key="index">{{item}}</p>
        </div>

      </div>
      <div class="courseInsturctFlexs">
        <div>
          <div class="courseTabFont">
            <p class="courseTabFontp" v-for="(item,index) in courseTab" :key="index" @click="changeCourTab(index)" :class="tabShow==index?'courTabActive':''">{{item.name}}</p>
          </div>
          <div v-show="tabShow=='1'">
            <p style="text-align: center;font-weight: bold;margin-bottom: 20px">{{courseVideoDetails.name}}</p>
            <p class="videoFOntCor">
              <span>课程名称：</span>
              <span>{{courseVideoDetails.name}}</span>
            </p>
            <p class="videoFOntCor">
              <span>学时安排：</span>
              <span>{{courseVideoDetails.totalClassHour}}</span>
            </p>
            <p class="videoFOntCor">
              <span>课程类别：</span>
              <span>{{courseVideoDetails.courseClassifyName}}</span>
            </p>
            <p class="videoFOntCor">
              <span>适用专业：</span>
              <span>{{courseVideoDetails.applicableToMajor}}</span>
            </p>
<!--            <div class="videoFOntCor">-->
<!--              <span>课程标准:</span>-->
<!--              <span style="margin-right: 30px" v-if="teachingOutlineObj">{{teachingOutlineObj.sourceChangeName?teachingOutlineObj.sourceChangeName:teachingOutlineObj.resourceName}}</span>-->
<!--              <el-button type="primary" plain size="small" @click="selectOutline()">查看</el-button>-->
<!--            </div>-->
            <p class="videoFOntCor">
              <span>备注: </span>
              <span>{{courseVideoDetails.remarks}}</span>
            </p>
          </div>
          <div v-show="tabShow=='0'">
            <el-menu
                default-active="0"
                class="el-menu-vertical-demo"
                :default-openeds="openeds">
              <el-submenu v-for="(item, index) in courseDetailArr"
                          :key="index"
                          :index="index+''">
                <template slot="title">
                  <span >{{ item.name }}</span>
                </template>
                <el-menu-item-group v-for="(itemChildren, indexChildren) in item.courseItemResources"
                                    :key="indexChildren" >
                  <el-menu-item :index="itemChildren.id+''" @click="goVideoDetail(itemChildren)">
                    <i class="el-icon-video-play"></i>
                    <span >{{ itemChildren.sourceRawName }}</span>
                    <template>
                      <img v-if="itemChildren.freeData==0&&newUserInfo&&!newUserInfo.teacherType&&isShowBuyBtn" src="images/lock.png" alt="" class="lock"/>
                      <img v-if="itemChildren.freeData==0&&newUserInfo&&newUserInfo.teacherType" src="images/lock.png" alt="" class="lock"/>
                      <img v-if="itemChildren.freeData==0&&!newUserInfo" src="images/lock.png" alt="" class="lock"/>
                    </template>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </div>
        </div>
        <div>
          <div class="teacherPadding">
            <p class="suitablePeopleTitle">授课教师</p>
            <div style="align-items: center;display: flex;">
              <img  v-if="courseVideoDetails.teacherUser && courseVideoDetails.teacherUser.logo" :src="courseVideoDetails.teacherUser.logo"/>
              <p style="font-size: 14px;margin: 6px 12px" v-if="courseVideoDetails.teacherUser && courseVideoDetails.teacherUser.name">{{courseVideoDetails.teacherUser.name}}</p>
            </div>
          </div>
<!--          <div class="suitablePeople">-->
<!--            <p class="suitablePeopleTitle">适合人群</p>-->
<!--            <div style="font-size: 14px;color: #999999">-->
<!--              {{courseVideoDetails.suitable}}-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <div class="pptShowWhindow">
      <el-dialog :visible.sync="pptWhindow" :show-close="true" >
        <div class="coursePPtImgFlexDetial">
          <div>
            <div class="pptresourceImgas" v-for="(item,index) in pptResourceImg" :key="index">
              <img :src="item?item:''" alt="" @click="changePPtImg(item)"/>
            </div>
          </div>
          <div>
            <el-image :src="coursePPtimg" :preview-src-list="pptResourceImg"  class="coursePPtBigIMg"></el-image>
          </div>
        </div>
      </el-dialog>
    </div>
    <el-drawer
        :visible.sync="courseTable"
        direction="ltr"
        size="280px">
      <div>
        <el-menu
            default-active=""
            class="el-menu-vertical-demo">
          <el-menu-item v-for="(item, index) in postcourseLIstArr"
                      :key="index"
                      :index="index+''">
            <template slot="title">
              <div style="display: flex;align-items: center" @click="goPostDetail(item)">
                <span >{{ item.name }}</span>
              </div>
            </template>
          </el-menu-item>
        </el-menu>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {
  addRecentStudy,
  addTrolley,
  audioImg, cancleCollectCource,
  checkUserCourseIsCollection, checkUserPayCourse, courseList, checkUserAddTrolley,
  getResourceListApi,
  playVoucher,
  resourceListByItem, studentcollectCource,
} from "@/api";
import {filePlayType} from "@/utils/fileUtil";

  export default {
    data(){
      return{
        courseTab:[
          {id:1,name:'视频列表'},
          {id:2,name:'课程介绍'}
        ],
        tabShow:'0',
        courseVideoDetails:{},
        teachingOutlineObj:{},
        pptWhindow:false,
        pptResourceImg:[],
        coursePPtimg:'',
        skus: [],
        courseDayNubmer:'',
        coursePrice:'',
        courseDetailArr: [],
        courseDetailData:{},
        courseDetailPage:1,
        courseDetailSize:30,
        isCollection:false,
        isShowBuyBtn:false,
        newUserInfo:{},
        isCollect:0,
        lockIcon:false,
        labels:[],
        openeds: ['0'],
        orderValidDays:'',
        courseTable:false,
        postcourseLIstArr:[],
        postCarShow:false
      }
    },
    methods:{
      //判读是否加入购物车
      getcheckUserAddTrolley(id){
        let data={
          id:id
        }
        checkUserAddTrolley(data).then(res=>{
          if(res.code==-10){
            this.postCarShow=true
          }else{
            this.postCarShow=false
          }
        })
      },
      //  获取课程列表
      getpostCourseList(claddFyId) {
        let data = {
          page: 1,
          size:50,
          ownerType:'platform',
          courseClassifyId:claddFyId,
          forEnterprise:'1'
        }
        courseList(data).then(res => {
          this.postcourseLIstArr = res.data.records;
        })
      },
      //加入购物车
      goShopping(item){
        let data={
          goodsPid:item.id,
          goodsType:'COURSE',
          time:this.orderValidDays?this.orderValidDays:this.courseDayNubmer.substr(0, this.courseDayNubmer.length - 1),
          price:this.coursePrice
        }
        addTrolley(data).then(res=>{
          if(res.code==0&&res.data==true){
            this.$message({
              message: '成功加入购物车',
              type: 'success'
            });
            this.postCarShow=true
          }else{
            this.$message('已经加入购物车了')
          }
        })
      },
      changeCourTab(index){
        this.tabShow=index;
      },
      //免费观看
      freeLook(){
        if(this.courseDetailArr[0].courseItemResources[0].freeData==0){
          this.$message('暂无购买,无法观看哦')
          return
        };
        sessionStorage.setItem('videoDetails',JSON.stringify(this.courseDetailArr[0].courseItemResources[0]))
        let routeUrl = this.$router.resolve({
          path: "/courseVideo",
          query:{free:'1',id:this.courseVideoDetails.id,videoId:this.courseDetailArr[0].courseItemResources[0].id}
        });
        window.open(routeUrl.href);
      },
      //去视频详情
      goVideoDetail(item){

        if(this.newUserInfo&&!this.newUserInfo.teacherType&&item.freeData==0&&this.isShowBuyBtn==true){
          this.$alert('暂无购买,无法观看,点击确定前往购买', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              document.documentElement.scrollTop=0
            }
          });
          return;
        };
        if(this.newUserInfo&&this.newUserInfo.teacherType&&item.freeData==0){
          this.$alert('暂无购买,无法观看,点击确定前往购买', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              document.documentElement.scrollTop=0
            }
          });
          return;
        };
        if(!this.newUserInfo&&item.freeData==0){
          this.$alert('暂无购买,无法观看,点击确定前往购买', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              document.documentElement.scrollTop=0
            }
          });
          return;
        }
        sessionStorage.setItem('videoDetails',JSON.stringify(item))
        let routeUrl = this.$router.resolve({
          path: "/courseVideo",
          query:{free:'1',id:this.courseVideoDetails.id,videoId:item.id}
        });
        window.open(routeUrl.href);
        let query={
          platformCourseId:item.platformCourseId,
          sectionNameOrVideo:item.sourceRawName
        }
        addRecentStudy(query).then(res=>{
        })
      },
      //  查询用户是够购买课程
      getUserBuyCourse(id){
        let data={
          courseId:id
        };
        checkUserPayCourse(data).then(res=>{
          if(res.code==0){
            this.isShowBuyBtn=false
          }else{
            this.isShowBuyBtn=true
          }
        })
      },
      //  收藏课程
      collectCource(){
        let data={
          courseId:this.courseVideoDetails.id
        };
        studentcollectCource(data).then(res=>{
          if(res.code==0){
            this.$message({
              message: "收藏成功",
              type: "success",
            });

            this.isCollect=1;
          }else{
            this.$message.error(res.msg)
          }
        })
      },
      //  取消收藏课程
      stdCancleCollectCource(){
        let data={
          courseId:this.courseVideoDetails.id
        };
        cancleCollectCource(data).then(res=>{
          if(res.code==0){
            this.$message({
              message: "取消收藏成功",
              type: "success",
            });
            // this.colloctCourceTag=true;
            // this.closecolloctCourceTag=false;
            this.isCollect=0
          }else{
            this.$message.error(res.msg)
          }
        })
      },
      //  点击天数改变钱数
      changeCourseDay(courPrice){
        this.coursePrice=courPrice
        this.skus.forEach(item=>{
          if(item.price==courPrice){
            this.orderValidDays=item.validDays
          }
        })
      },
      //  个人课程教学大纲
      // getmyCourceDg(){
      //   let data={
      //     platformCourseId: this.courseVideoDetails.id,
      //     syllabusData:1
      //   };
      //   getResourceListApi(data).then(res=>{
      //     this.teachingOutlineObj=res.data.records[0]
      //   })
      // },
      //查看教学大纲
      selectOutline(){
        if(!this.teachingOutlineObj) return;
        let fileType = filePlayType(this.teachingOutlineObj.sourceChangeName?this.teachingOutlineObj.sourceChangeName:this.teachingOutlineObj.resourceName);
        if(fileType === 'avTypeAry'||fileType === 'audioTypeAry'){
          let data = {
            id: this.teachingOutlineObj.id
          }
          this.videoOptions.vid=this.teachingOutlineObj.thirdPartyStorageId
          playVoucher(data).then((res)=>{
            this.videoOptions.playauth = res.data.playAuth
            this.videoWhindow = true;
          })
        }
        if(fileType === 'imageTypeAry'){
          if(this.$route.query.myCourseDetail){
            let dataArr=[]
            let data = {
              thirdPartyStorageId: this.teachingOutlineObj.thirdPartyStorageId
            }
            dataArr.push(data)
            audioImg({queryDataList:dataArr}).then(res => {
              res.data.forEach(item=>{
                this.resourceImg.push(item.url);
              })
              this.outlineImg=res.data[0].url
            });
          }else{
            this.resourceImg.push(this.teachingOutlineObj.sourceChangeReadUrl);
            this.outlineImg=this.teachingOutlineObj.sourceChangeReadUrl;
          }
          this.designWhindow = true;
        }
        if(fileType === 'officeTypeAry'||fileType === 'pdfTypeAry'){
          if(this.$route.query.myCourseDetail){
            let dataArr=[]
            let data = {
              thirdPartyStorageId: this.teachingOutlineObj.thirdPartyStorageId
            }
            dataArr.push(data)
            audioImg({queryDataList:dataArr}).then(res => {
              res.data.forEach(item=>{
                this.pptResourceImg = item.aliVideoOssUrl.split(',');
                this.coursePPtimg=this.pptResourceImg[0]
              })
            });
          }else{
            this.pptResourceImg=this.teachingOutlineObj.sourceChangeReadUrl.split(',');
            this.coursePPtimg=this.pptResourceImg[0]
          }
          this.pptWhindow = true;
        }
      },
      //  点击小图切换大图
      changePPtImg(item){
        this.coursePPtimg=item
      },
      //获取章和视频列表
      getResourceListByItem(id){
        let data={
          platformCourseId:id,
          sourceDiff: 'MicroLecture'
        }
        resourceListByItem(data).then(res=>{
          this.courseDetailArr=res.data

        })
      },
      //获取课程列表
      getCourseList() {
        let data={
          page:1,
          size:50,
          schoolId:this.newUserInfo.schoolId
        }
        courseList(data).then(res => {
          if(res.code==0&&res.data.records){
            res.data.records.forEach(item=>{
              if(item.id==this.courseVideoDetails.id){
                this.lockIcon=true
              }
            })
          }
        })
      },
      //购买
      postCourseBuy(item){
        let skuIdData=""
        this.courseVideoDetails.skus.forEach(item1=>{
          if(item1.validDays+'天'==this.courseDayNubmer){
            skuIdData=item1.id;
          }else if(item1.price==this.courseDayNubmer){
            skuIdData=item1.id;
          }
        })
        let routeUrl = this.$router.resolve({
          path: "/courseOrder",
          query:{payNumber:item.courseActivityVo?(this.coursePrice*item.courseActivityVo.price).toFixed(2):this.coursePrice,originalPrice:this.coursePrice,skuId:skuIdData,path:'post'}
        });
        window.open(routeUrl.href);
      },
      //更多课程
      openCourse(){
        this.getpostCourseList(this.$route.query.classFyid);
        this.courseTable = true
      },
      //切换课程
      goPostDetail(item){
        this.courseTable=false
        this.getResourceListByItem(item.id);
        this.getUserBuyCourse(item.id);
        this.courseVideoDetails=item
      }
    },
    mounted() {
      this.$other.$emit('setIndexNav',4);
      this.newUserInfo=JSON.parse(localStorage.getItem("userInfo")) ;
      let courseVideoDetails=JSON.parse(sessionStorage.getItem('courseVideoDetails'));
      this.courseVideoDetails=courseVideoDetails
      this.labels=courseVideoDetails.keyWord.split(',')
      // this.getCourseList()
      if(courseVideoDetails){
        if(courseVideoDetails.whetherCollection==0){
          this.isCollect=0
        }else{
          this.isCollect=1
        }
      }
      this.teachingOutlineObj=courseVideoDetails.courseItemResources[0]
      if(this.courseVideoDetails.skus.length){
        let arr=[]
        this.courseDayNubmer=this.courseVideoDetails.skus[0].validDays+'天';
        this.coursePrice=this.courseVideoDetails.skus[0].price;
        this.courseVideoDetails.skus.forEach(item=>{
          if(item.platformType=='web'){
            arr.push(item)
          }
        })
        this.skus=arr;
      }
      this.getUserBuyCourse(courseVideoDetails.id);
      this.getResourceListByItem(courseVideoDetails.id);
      this.getcheckUserAddTrolley(courseVideoDetails.id)
    },
  }
</script>
<style>
@import "../../../public/css/courseVideoDetails.css";
</style>
