<template>
  <div class="courseDetailBg">
    <div class="courseDetailBgIMg">
      <div class="courseDetailCenter">
        <div class="courseDetailFlex">
          <div class="courseImgs">
            <img :src="courseDetail?courseDetail.logo:''" alt=""/>
          </div>
          <div class="coursedtailAb">
            <div class="courseInstruct">
              <h4 style="font-size: 20px">{{ courseDetail.name }}</h4>
              <div class="coursemrTop">
                <span>章节:{{ courseDetail.chapterNumber?courseDetail.chapterNumber:count }}个</span>
                <span v-if="courseDetail&&courseDetail.resourceDuration">课程时长:{{ parseInt(courseDetail.resourceDuration / 60) }}分钟</span>
                <span v-if="courseDetail&&courseDetail.difficultyLevel">难易度:{{ courseDetail.difficultyLevel }}</span>
                <!--                <span>补充资源:暂无</span>-->
              </div>
              <p class="courseContents">
                {{ courseDetail.profiles }}
              </p>
              <div class="courseCollect" v-if="newUserInfo&&!newUserInfo.teacherType" >
                <div v-if="courseDetail.whetherCollection==0" @click="collectCource">
                  <img src="images/collect.png" alt=""/>
                  <span>收藏</span>
                </div>
                <div v-if="courseDetail.whetherCollection==1" @click="stdCancleCollectCource">
                  <img src="images/collects.png" alt=""/>
                  <span>已收藏</span>
                </div>
                <div @click="share()">
                  <img src="images/share.png" alt="" style="width: 14px;margin-top: 3px;"/>
                  <span>分享</span>
                </div>
              </div>
            </div>
            <template v-if="newUserInfo&&!newUserInfo.teacherType&&isShowBuyBtn">
              <div class="courseBuy" v-for="(item,index) in skus.slice(0,1)" :key="index" >
                <div>
                  <h2 v-if="item.courseActivityVo">¥{{(coursePrice*item.courseActivityVo.price).toFixed(2)}}</h2>
                  <span v-if="item.courseActivityVo">(¥{{ coursePrice }})</span>
                  <h2 v-if="!item.courseActivityVo">¥{{(coursePrice)}}</h2>
                </div>
                <el-select v-model="courseDayNubmer" placeholder="有效天数" @change="changeCourseDay(courseDayNubmer)">
                  <el-option
                      v-for="(item1,index1) in skus"
                      :key="item1.price"
                      :label="item1.validDays+'天'"
                      :value="item1.price"
                      >
                  </el-option>
                </el-select>
                <p @click="promptlyBuy(item)" >立即购买</p>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="courseDetailLeft">
        <!--      学校tab-->
        <div v-if="!enterprise" class="schoolCourse">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane v-if="newUserInfo&&newUserInfo.teacherType" label="课程标准" name="first">
              <div class="teachingOutline">
                <p>{{courseDetail.name}}</p>
                <div class="outlineBorder">
                  <table >
                    <tr>
                      <td>课程编码</td>
                      <td>{{courseDetail.courseCode}}</td>
                    </tr>
                    <tr>
                      <td>课程名称</td>
                      <td>{{courseDetail.name}}</td>
                    </tr>
                    <tr>
                      <td>课程标准</td>
                      <td>
                        <div class="teachingFlexoutline" v-if="teachingOutlineObj">
                          <span >{{teachingOutlineObj.sourceChangeName?teachingOutlineObj.sourceChangeName:teachingOutlineObj.resourceName}}</span>
                          <el-button type="primary" plain @click="selectOutline()">查看</el-button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>课程类别</td>
                      <td>{{courseDetail.courseClassifyName}}</td>
                    </tr>
                    <tr>
                      <td>授课对象</td>
                      <td>{{courseDetail.orientCrowd}}</td>
                    </tr>
                    <tr>
                      <td>课时安排</td>
                      <td>{{courseDetail.totalClassHour}}课时</td>
                    </tr>
                    <tr>
                      <td>适用专业</td>
                      <td>{{courseDetail.applicableToMajor}}</td>
                    </tr>
                    <tr>
                      <td>建议开课学期</td>
                      <td>{{courseDetail.semesterTime}}</td>
                    </tr>
                    <tr>
                      <td>备注</td>
                      <td>{{courseDetail.remarks}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane v-if="newUserInfo&&newUserInfo.teacherType" label="章节内容" name="second">
              <!--      章节内容-->
              <div class="courseDetailobjectiveFlex" >
                <div class="courseDetailL">
                  <el-menu
                      :default-active="taskActiveId"
                      class="el-menu-vertical-demo"
                  >
                    <el-submenu
                        v-for="(item, index) in courseDetailArr"
                        :key="index"
                        :index="item.id?item.id:''">
                      <template slot="title">
                        <span class="jieTitle">{{ item.name }}</span>
                      </template>
                      <el-menu-item-group
                          v-for="(itemChildren, indexChildren) in item.children"
                          :key="indexChildren"
                      >
                        <el-menu-item :index="itemChildren.id+''">
                          <div slot="title" @click="chooseChilrden(itemChildren)" v-if="newUserInfo&&newUserInfo.teacherType">
                            <span class="jieTitle" >{{ itemChildren.name }}</span>
<!--                             <span class="trySee">试看</span>-->
                            <template v-if="!myCourseDetail">
                            <img v-if="itemChildren.freeData==0&&!newUserInfo.teacherType&&isShowBuyBtn" src="images/lock.png" alt="" class="lock"/>
                            <img v-if="itemChildren.freeData==0&&newUserInfo.teacherType&&!lockIcon" src="images/lock.png" alt="" class="lock"/>
                            </template>
                          </div>
                        </el-menu-item>
                      </el-menu-item-group>
                    </el-submenu>
                  </el-menu>
                </div>
                <div class="courseDetailFlexBackground">
                  <div class="CourseClassifys" v-if="newUserInfo&&newUserInfo.teacherType">
                    <p
                        class="CourseClassifysp"
                        @click="changeCourseClassify(index)"
                        v-for="(item, index) in CourseClassify"
                        :key="index"
                        :class="courseClassfifyActive === index ? 'courseClassifys' : ''"
                    >
                      {{ item.name }}
                    </p>
                  </div>
                  <div class="CourseClassifycontent" >
                    <div v-if="freeData==1||newUserInfo.teacherType||lockIcon">
                      <template v-if="newUserInfo&&newUserInfo.teacherType">
                        <div v-if="[0,1,2,7].includes(courseClassfifyActive)">
                          <div class="designFlex" v-for="(item,index) in coursemiCrolecture" :key="index">
                            <p>{{ item.sourceRawName?item.sourceRawName:item.resourceName }}</p>
                            <el-button type="primary" plain @click="designWindowShow(item)">立即查看</el-button>
                          </div>
                          <div v-if="!coursemiCrolecture.length" style="width: 100%">
                            <el-empty :image-size="200"></el-empty>
                          </div>
                          <div style="margin-top: 60px;">
                            <el-pagination
                                class="text_center"
                                background
                                @current-change="handleDesign"
                                :current-page.sync="courseDetailPage"
                                :page-size="courseDetailSize"
                                layout="total,  prev, pager, next, jumper"
                                :total="courseDetailTotal"
                            >
                            </el-pagination>
                          </div>
                        </div>
                      </template>

                      <template v-if="newUserInfo&&newUserInfo.teacherType">
                        <div v-if="[4,5,6].includes(courseClassfifyActive)" >
                          <div class="courseVideoFlex">
                            <div class="courseVideo" v-for="(item,index) in coursemiCrolecture" :key="index"
                                 @click="designWindowShow(item)">
                              <img
                                  :src="item.voideLogoUrl?item.voideLogoUrl:'images/zanwu.png'"
                                  alt=""
                                  class="courseVideoImg"

                              />
                              <div class="courseVideoTitle">
                                <p>{{ item.sourceRawName }}</p>
                                <!--                              <p>雪颜老师</p>-->
                              </div>
                              <img src="images/paly.png" alt="" class="playVideos">
                            </div>
                          </div>
                          <div v-if="!coursemiCrolecture.length" style="width: 100%">
                            <el-empty :image-size="200"></el-empty>
                          </div>
                          <div style="margin-top: 60px;">
                            <el-pagination
                                class="text_center"
                                background
                                @current-change="handleDesign"
                                :current-page.sync="courseDetailPage"
                                :page-size="courseDetailSize"
                                layout="total,  prev, pager, next, jumper"
                                :total="courseDetailTotal"
                            >
                            </el-pagination>
                          </div>
                        </div>
                      </template>
                      <template v-if="newUserInfo&&!newUserInfo.teacherType">
                        <div v-if="[0].includes(courseClassfifyActive)" >
                          <div class="courseVideoFlex">
                            <div class="courseVideo" v-for="(item,index) in coursemiCrolecture" :key="index"
                                 @click="designWindowShow(item)">
                              <img
                                  :src="item.voideLogoUrl?item.voideLogoUrl:'images/zanwu.png'"
                                  alt=""
                                  class="courseVideoImg"

                              />
                              <div class="courseVideoTitle">
                                <p>{{ item.sourceRawName }}</p>
                                <!--                              <p>雪颜老师</p>-->
                              </div>
                              <img src="images/paly.png" alt="" class="playVideos">
                            </div>
                          </div>
                          <div v-if="!coursemiCrolecture.length" style="width: 100%">
                            <el-empty :image-size="200"></el-empty>
                          </div>
                          <div style="margin-top: 60px;">
                            <el-pagination
                                class="text_center"
                                background
                                @current-change="handleDesign"
                                :current-page.sync="courseDetailPage"
                                :page-size="courseDetailSize"
                                layout="total,  prev, pager, next, jumper"
                                :total="courseDetailTotal"
                            >
                            </el-pagination>
                          </div>
                        </div>
                      </template>
                      <div v-if="courseClassfifyActive ===3">
                        <div v-if="!myCourseDetail">
                          <div class="assignmentBorder" >
                            <span
                                class="assignment"
                                @click="changeAssignment(1)"
                                :class="assignmentShow == 1 ? 'assignmentActive' : ''"
                            >作业</span>
                            <span
                                class="assignment"
                                @click="changeAssignment(2)"
                                :class="assignmentShow == 2 ? 'assignmentActive' : ''"
                            >习题</span>
                          </div>
                          <div v-for="(item,index) in questionList" :key="index">
                            <exercises-s-c
                                ref="std"
                                v-if="item.exercisesType === 'SC'"
                                :exercisesInfo="item"
                                :openType="2"
                                :isShowRightAndAnalysis="true"
                                :exercisesListIndex="index+1"
                            ></exercises-s-c>
                            <exercises-m-c
                                ref="std"
                                v-if="item.exercisesType === 'MC'"
                                :exercisesInfo="item"
                                :openType="2"
                                :isShowRightAndAnalysis="true"
                                :exercisesListIndex="index+1"
                            ></exercises-m-c>
                            <!-- 填空题 -->
                            <exercises-f-b
                                ref="std"
                                v-if="item.exercisesType === 'FB'"
                                :exercisesInfo="item"
                                :openType="2"
                                :isShowRightAndAnalysis="true"
                                :exercisesListIndex="index+1"
                            >
                            </exercises-f-b>
                            <!-- 判断题 -->
                            <exercises-t-f
                                ref="std"
                                v-if="item.exercisesType=== 'TF'"
                                :exercises-info="item"
                                :open-type="2"
                                :isShowRightAndAnalysis="true"
                                :exercisesListIndex="index+1"
                            ></exercises-t-f>
                            <!-- 简答题 -->
                            <exercises-s-a-q
                                ref="std"
                                v-else-if="item.exercisesType === 'SAQ'"
                                :exercisesInfo="item"
                                :openType="2"
                                :isShowRightAndAnalysis="true"
                                :exercisesListIndex="index+1"
                            >
                            </exercises-s-a-q>
                          </div>
                          <div v-if="!questionList.length" style="width: 100%">
                            <el-empty :image-size="200"></el-empty>
                          </div>
                          <div style="margin-top: 30px;" >
                            <el-pagination
                                class="text_center"
                                background
                                @current-change="handleworkList"
                                :current-page.sync="workPage"
                                :page-size="worksize"
                                layout="total,  prev, pager, next, jumper"
                                :total="worktotal"
                            >
                            </el-pagination>
                          </div>
                        </div>
                        <div v-if="myCourseDetail" v-for="(item,index) in questionList" :key="index">
                          <exercises-s-c
                              ref="std"
                              v-if="item.customText.exercisesType === 'SC'"
                              :exercisesInfo="item.customText"
                              :openType="2"
                              :isShowRightAndAnalysis="true"
                              :exercisesListIndex="index+1"
                          ></exercises-s-c>
                          <exercises-m-c
                              ref="std"
                              v-if="item.customText.exercisesType === 'MC'"
                              :exercisesInfo="item.customText"
                              :openType="2"
                              :isShowRightAndAnalysis="true"
                              :exercisesListIndex="index+1"
                          ></exercises-m-c>
                          <!-- 填空题 -->
                          <exercises-f-b
                              ref="std"
                              v-if="item.customText.exercisesType === 'FB'"
                              :exercisesInfo="item.customText"
                              :openType="2"
                              :isShowRightAndAnalysis="true"
                              :exercisesListIndex="index+1"
                          >
                          </exercises-f-b>
                          <!-- 判断题 -->
                          <exercises-t-f
                              ref="std"
                              v-if="item.customText.exercisesType === 'TF'"
                              :exercises-info="item.customText"
                              :open-type="2"
                              :isShowRightAndAnalysis="true"
                              :exercisesListIndex="index+1"
                          ></exercises-t-f>
                          <!-- 简答题 -->
                          <exercises-s-a-q
                              ref="std"
                              v-else-if="item.customText.exercisesType === 'SAQ'"
                              :exercisesInfo="item.customText"
                              :openType="2"
                              :isShowRightAndAnalysis="true"
                              :exercisesListIndex="index+1"
                          >
                          </exercises-s-a-q>
                        </div>
                        <div style="margin-top: 60px;" v-if="myCourseDetail">
                          <el-pagination
                              class="text_center"
                              background
                              @current-change="handleDesign"
                              :current-page.sync="courseDetailPage"
                              :page-size="courseDetailSize"
                              layout="total,  prev, pager, next, jumper"
                              :total="courseDetailTotal"
                          >
                          </el-pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane v-if="newUserInfo&&!newUserInfo.teacherType" label="章节内容" name="second">
              <!--      章节内容-->
              <div class="courseDetailobjectiveFlex" >
                <div class="courseDetailL userCourseDetail">
                  <el-menu
                      :default-active="taskActiveId"
                      class="el-menu-vertical-demo"
                  >
                    <el-submenu
                        v-for="(item, index) in courseDetailArr"
                        :key="index"
                        :index="item.id?item.id:''">
                      <template slot="title">
                        <span class="jieTitle">{{ item.name }}</span>
                        <img v-if="item.freeData==0&&isShowBuyBtn" src="images/lock.png" alt="" class="lock"/>
                      </template>
                      <el-menu-item-group
                          v-for="(itemChildren, indexChildren) in item.courseItemVos"
                          :key="indexChildren"
                      >
<!--                        <el-menu-item :index="itemChildren.id+''">-->
<!--                          <div slot="title"  >-->
<!--                            <p class="jieTitle" >-->
<!--                              {{ itemChildren.name }}-->
<!--&lt;!&ndash;                              <el-menu-item >选项1</el-menu-item>&ndash;&gt;-->
<!--                            </p>-->
<!--                            &lt;!&ndash;                             <span class="trySee">试看</span>&ndash;&gt;-->
<!--                            <template v-if="!myCourseDetail">-->
<!--                              <img v-if="itemChildren.freeData==0&&!newUserInfo.teacherType&&isShowBuyBtn" src="images/lock.png" alt="" class="lock"/>-->
<!--                              <img v-if="itemChildren.freeData==0&&newUserInfo.teacherType&&!lockIcon" src="images/lock.png" alt="" class="lock"/>-->
<!--                            </template>-->
<!--                          </div>-->
<!--                          -->
<!--                        </el-menu-item>-->
                        <template v-for="(item2,index2) in itemChildren.courseItemResources">
                          <el-menu-item  :index="item2.id+''" @click="getFindItemList(item2,item,itemChildren)" class="sourceWd">
                            <div>
                              <span>{{item2.sourceRawName}}</span>
                              <img v-if="itemChildren.freeData==0&&isShowBuyBtn" src="images/lock.png" alt="" class="lock"/>
                            </div>
                          </el-menu-item>
                        </template>
                      </el-menu-item-group>
                    </el-submenu>
                  </el-menu>
                </div>
                <div class="courseDetailFlexBackground">
                  <div v-if="newUserInfo&&!newUserInfo.teacherType">
                    <template v-if="videoWhindow2">
                      <vue-aliplayer-v2 style="height: 660px"  @ready="handleAliplayerReady" ref="VueAliplayerV2" :options="videoOptions" />
                    </template>
                    <template v-if="isShowBuyBtn&&freeDataValue">
                      <el-empty description="暂无观看权限"></el-empty>
                    </template>
                  </div>
                  <div class="CourseClassifys" v-if="newUserInfo&&newUserInfo.teacherType">
                    <p
                        class="CourseClassifysp"
                        @click="changeCourseClassify(index)"
                        v-for="(item, index) in CourseClassify"
                        :key="index"
                        :class="courseClassfifyActive === index ? 'courseClassifys' : ''"
                    >
                      {{ item.name }}
                    </p>
                  </div>
                  <div class="CourseClassifycontent" v-if="newUserInfo&&newUserInfo.teacherType">
                    <div v-if="freeData==1||newUserInfo.teacherType||lockIcon">
                      <template v-if="newUserInfo&&newUserInfo.teacherType">
                        <div v-if="[0,1,2,7].includes(courseClassfifyActive)">
                          <div class="designFlex" v-for="(item,index) in coursemiCrolecture" :key="index">
                            <p>{{ item.sourceRawName?item.sourceRawName:item.resourceName }}</p>
                            <el-button type="primary" plain @click="designWindowShow(item)">立即查看</el-button>
                          </div>
                          <div v-if="!coursemiCrolecture.length" style="width: 100%">
                            <el-empty :image-size="200"></el-empty>
                          </div>
                          <div style="margin-top: 60px;">
                            <el-pagination
                                class="text_center"
                                background
                                @current-change="handleDesign"
                                :current-page.sync="courseDetailPage"
                                :page-size="courseDetailSize"
                                layout="total,  prev, pager, next, jumper"
                                :total="courseDetailTotal"
                            >
                            </el-pagination>
                          </div>
                        </div>
                      </template>

                      <template v-if="newUserInfo&&newUserInfo.teacherType">
                        <div v-if="[4,5,6].includes(courseClassfifyActive)" >
                          <div class="courseVideoFlex">
                            <div class="courseVideo" v-for="(item,index) in coursemiCrolecture" :key="index"
                                 @click="designWindowShow(item)">
                              <img
                                  :src="item.voideLogoUrl?item.voideLogoUrl:'images/zanwu.png'"
                                  alt=""
                                  class="courseVideoImg"

                              />
                              <div class="courseVideoTitle">
                                <p>{{ item.sourceRawName }}</p>
                                <!--                              <p>雪颜老师</p>-->
                              </div>
                              <img src="images/paly.png" alt="" class="playVideos">
                            </div>
                          </div>
                          <div v-if="!coursemiCrolecture.length" style="width: 100%">
                            <el-empty :image-size="200"></el-empty>
                          </div>
                          <div style="margin-top: 60px;">
                            <el-pagination
                                class="text_center"
                                background
                                @current-change="handleDesign"
                                :current-page.sync="courseDetailPage"
                                :page-size="courseDetailSize"
                                layout="total,  prev, pager, next, jumper"
                                :total="courseDetailTotal"
                            >
                            </el-pagination>
                          </div>
                        </div>
                      </template>
                      <template v-if="newUserInfo&&!newUserInfo.teacherType">
                        <div v-if="[0].includes(courseClassfifyActive)" >
                          <div class="courseVideoFlex">
                            <div class="courseVideo" v-for="(item,index) in coursemiCrolecture" :key="index"
                                 @click="designWindowShow(item)">
                              <img
                                  :src="item.voideLogoUrl?item.voideLogoUrl:'images/zanwu.png'"
                                  alt=""
                                  class="courseVideoImg"

                              />
                              <div class="courseVideoTitle">
                                <p>{{ item.sourceRawName }}</p>
                                <!--                              <p>雪颜老师</p>-->
                              </div>
                              <img src="images/paly.png" alt="" class="playVideos">
                            </div>
                          </div>
                          <div v-if="!coursemiCrolecture.length" style="width: 100%">
                            <el-empty :image-size="200"></el-empty>
                          </div>
                          <div style="margin-top: 60px;">
                            <el-pagination
                                class="text_center"
                                background
                                @current-change="handleDesign"
                                :current-page.sync="courseDetailPage"
                                :page-size="courseDetailSize"
                                layout="total,  prev, pager, next, jumper"
                                :total="courseDetailTotal"
                            >
                            </el-pagination>
                          </div>
                        </div>
                      </template>
                      <div v-if="courseClassfifyActive ===3">
                        <div v-if="!myCourseDetail">
                          <div class="assignmentBorder" >
                            <span
                                class="assignment"
                                @click="changeAssignment(1)"
                                :class="assignmentShow == 1 ? 'assignmentActive' : ''"
                            >作业</span>
                            <span
                                class="assignment"
                                @click="changeAssignment(2)"
                                :class="assignmentShow == 2 ? 'assignmentActive' : ''"
                            >习题</span>
                          </div>
                          <div v-for="(item,index) in questionList" :key="index">
                            <exercises-s-c
                                ref="std"
                                v-if="item.exercisesType === 'SC'"
                                :exercisesInfo="item"
                                :openType="2"
                                :isShowRightAndAnalysis="true"
                                :exercisesListIndex="index+1"
                            ></exercises-s-c>
                            <exercises-m-c
                                ref="std"
                                v-if="item.exercisesType === 'MC'"
                                :exercisesInfo="item"
                                :openType="2"
                                :isShowRightAndAnalysis="true"
                                :exercisesListIndex="index+1"
                            ></exercises-m-c>
                            <!-- 填空题 -->
                            <exercises-f-b
                                ref="std"
                                v-if="item.exercisesType === 'FB'"
                                :exercisesInfo="item"
                                :openType="2"
                                :isShowRightAndAnalysis="true"
                                :exercisesListIndex="index+1"
                            >
                            </exercises-f-b>
                            <!-- 判断题 -->
                            <exercises-t-f
                                ref="std"
                                v-if="item.exercisesType=== 'TF'"
                                :exercises-info="item"
                                :open-type="2"
                                :isShowRightAndAnalysis="true"
                                :exercisesListIndex="index+1"
                            ></exercises-t-f>
                            <!-- 简答题 -->
                            <exercises-s-a-q
                                ref="std"
                                v-else-if="item.exercisesType === 'SAQ'"
                                :exercisesInfo="item"
                                :openType="2"
                                :isShowRightAndAnalysis="true"
                                :exercisesListIndex="index+1"
                            >
                            </exercises-s-a-q>
                          </div>
                          <div v-if="!questionList.length" style="width: 100%">
                            <el-empty :image-size="200"></el-empty>
                          </div>
                          <div style="margin-top: 30px;" >
                            <el-pagination
                                class="text_center"
                                background
                                @current-change="handleworkList"
                                :current-page.sync="workPage"
                                :page-size="worksize"
                                layout="total,  prev, pager, next, jumper"
                                :total="worktotal"
                            >
                            </el-pagination>
                          </div>
                        </div>
                        <div v-if="myCourseDetail" v-for="(item,index) in questionList" :key="index">
                          <exercises-s-c
                              ref="std"
                              v-if="item.customText.exercisesType === 'SC'"
                              :exercisesInfo="item.customText"
                              :openType="2"
                              :isShowRightAndAnalysis="true"
                              :exercisesListIndex="index+1"
                          ></exercises-s-c>
                          <exercises-m-c
                              ref="std"
                              v-if="item.customText.exercisesType === 'MC'"
                              :exercisesInfo="item.customText"
                              :openType="2"
                              :isShowRightAndAnalysis="true"
                              :exercisesListIndex="index+1"
                          ></exercises-m-c>
                          <!-- 填空题 -->
                          <exercises-f-b
                              ref="std"
                              v-if="item.customText.exercisesType === 'FB'"
                              :exercisesInfo="item.customText"
                              :openType="2"
                              :isShowRightAndAnalysis="true"
                              :exercisesListIndex="index+1"
                          >
                          </exercises-f-b>
                          <!-- 判断题 -->
                          <exercises-t-f
                              ref="std"
                              v-if="item.customText.exercisesType === 'TF'"
                              :exercises-info="item.customText"
                              :open-type="2"
                              :isShowRightAndAnalysis="true"
                              :exercisesListIndex="index+1"
                          ></exercises-t-f>
                          <!-- 简答题 -->
                          <exercises-s-a-q
                              ref="std"
                              v-else-if="item.customText.exercisesType === 'SAQ'"
                              :exercisesInfo="item.customText"
                              :openType="2"
                              :isShowRightAndAnalysis="true"
                              :exercisesListIndex="index+1"
                          >
                          </exercises-s-a-q>
                        </div>
                        <div style="margin-top: 60px;" v-if="myCourseDetail">
                          <el-pagination
                              class="text_center"
                              background
                              @current-change="handleDesign"
                              :current-page.sync="courseDetailPage"
                              :page-size="courseDetailSize"
                              layout="total,  prev, pager, next, jumper"
                              :total="courseDetailTotal"
                          >
                          </el-pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <!--      企业tab-->
        <div v-if="enterprise" class="enterpriseCourse">
          <div class="courseDetailobjectiveFlex">
            <div class="courseDetailL">
              <el-menu
                  :default-active="taskActiveId"
                  class="el-menu-vertical-demo"
              >
                <el-submenu
                    v-for="(item, index) in courseDetailArr"
                    :key="index"
                    :index="item.id?item.id:''">
                  <template slot="title">
                    <span class="jieTitle">{{ item.name }}</span>
                  </template>
                  <el-menu-item-group
                      v-for="(itemChildren, indexChildren) in item.children"
                      :key="indexChildren"
                  >
                    <el-menu-item :index="itemChildren.id+''">
                      <div slot="title" @click="chooseCourseResource(itemChildren)">
                        <span class="jieTitle" >{{ itemChildren.name }}</span>
                        <!-- <span class="trySee">试看</span> -->
                        <img v-if="itemChildren.freeData==0&&!newUserInfo.teacherType&&isShowBuyBtn" src="images/lock.png" alt="" class="lock"/>
                        <img v-if="itemChildren.freeData==0&&newUserInfo.teacherType&&!lockIcon" src="images/lock.png" alt="" class="lock"/>
                      </div>
                    </el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
              </el-menu>
            </div>
            <div class="courseDetailFlexBackground">
<!--              <div class="CourseClassifys">-->
<!--                <p-->
<!--                    class="CourseClassifysp"-->
<!--                    @click="enterpriseCourseClassify(index)"-->
<!--                    v-for="(item, index) in enterpriseCourseArr"-->
<!--                    :key="index"-->
<!--                    :class="courseClassfifyActive === index ? 'courseClassifys' : ''"-->
<!--                >-->
<!--                  {{ item.name }}-->
<!--                </p>-->
<!--              </div>-->
              <div class="enterpriseCourseClassifycontent" >
                <div v-if="freeData==1||newUserInfo.teacherType||lockIcon">
                  <template>
                    <el-tabs v-model="courseActiveName" type="card" @tab-click="enterpriseCourseClassify">
                      <el-tab-pane label="电子教材" name="textbook">
                        <div style="padding-top: 30px">
                          <div class="designFlex" v-for="(item,index) in coursemiCrolecture" :key="index">
                            <p>{{ item.sourceRawName?item.sourceRawName:item.resourceName }}</p>
                            <el-button type="primary" plain @click="designWindowShow(item)">立即查看</el-button>
                          </div>
                          <div v-if="!coursemiCrolecture.length" style="width: 100%">
                            <el-empty :image-size="200"></el-empty>
                          </div>
                          <div style="margin-top: 60px;">
                            <el-pagination
                                class="text_center"
                                background
                                @current-change="handleDesignresource"
                                :current-page.sync="courseDetailPage"
                                :page-size="courseDetailSize"
                                layout="total,  prev, pager, next, jumper"
                                :total="courseDetailTotal"
                            >
                            </el-pagination>
                          </div>
                        </div>
                      </el-tab-pane>
                      <el-tab-pane label="课程微课" name="microlecture">
                        <div style="padding-top: 30px">
                          <div class="courseVideoFlex">
                            <div class="courseVideo" v-for="(item,index) in coursemiCrolecture" :key="index"
                                 @click="designWindowShow(item)">
                              <img
                                  :src="item.voideLogoUrl?item.voideLogoUrl:'images/zanwu.png'"
                                  alt=""
                                  class="courseVideoImg"

                              />
                              <div class="courseVideoTitle">
                                <p>{{ item.sourceRawName }}</p>
                                <!--                              <p>雪颜老师</p>-->
                              </div>
                              <img src="images/paly.png" alt="" class="playVideos">
                            </div>
                          </div>
                          <div v-if="!coursemiCrolecture.length" style="width: 100%">
                            <el-empty :image-size="200"></el-empty>
                          </div>
                          <div style="margin-top: 60px;">
                            <el-pagination
                                class="text_center"
                                background
                                @current-change="handleDesignresource"
                                :current-page.sync="courseDetailPage"
                                :page-size="courseDetailSize"
                                layout="total,  prev, pager, next, jumper"
                                :total="courseDetailTotal"
                            >
                            </el-pagination>
                          </div>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 教学设计 -->
    <el-dialog :visible.sync="designWhindow" :show-close="true">
      <div class="resourceImgas" >
        <el-image
            :src="outlineImg"
            :preview-src-list="resourceImg">
        </el-image>
      </div>
    </el-dialog>
<!--    ppt-->
    <div class="pptShowWhindow">
      <el-dialog :visible.sync="pptWhindow" :show-close="true" >
        <div class="coursePPtImgFlexDetial">
          <div>
            <div class="pptresourceImgas" v-for="(item,index) in pptResourceImg" :key="index">
              <img :src="item?item:''" alt="" @click="changePPtImg(item)"/>
            </div>
          </div>
          <div>
            <el-image :src="coursePPtimg" :preview-src-list="pptResourceImg"  class="coursePPtBigIMg"></el-image>
          </div>
        </div>
      </el-dialog>
    </div>
<!--    视频播放-->
    <el-dialog
      :visible.sync="videoWhindow"
      :show-close="true"
      :append-to-body="true"
      :destroy-on-close="true">
        <div class="resourceImgas" v-if="videoWhindow">
          <vue-aliplayer-v2  @ready="handleAliplayerReady" ref="VueAliplayerV2" :options="videoOptions" />
        </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  checkUserPayCourse,
  getResourceListApi,
  playVoucher,
  operationTopic,
  audioImg,
  selectAddTeacherResourceList,
  studentcollectCource,
  cancleCollectCource,
  appendPlayLog,
  courseList,
  addRecentStudy,
  findItemList,
selectCollectCourceId
} from "@/api";
import {
  checkUserCourseIsCollection,
  appendUserCollectionCourseId,
  deletedUserCollectionCourseId,
  // selectCollectCourceId
} from "@/api/index.js"
import {filePlayType} from "@/utils/fileUtil.js";
import VueAliplayerV2 from 'vue-aliplayer-v2';
import ExercisesTF from "@/components/exercisesList/exercisesTF";
import ExercisesSC from "@/components/exercisesList/exercisesSC";
import ExercisesMC from "@/components/exercisesList/exercisesMC";
import ExercisesFB from "@/components/exercisesList/exercisesFB";
import ExercisesSAQ from "@/components/exercisesList/exercisesSAQ";
import myClass from "@/views/userCenter/components/myClass";
export default {
  components:{
    VueAliplayerV2,ExercisesTF, ExercisesSC, ExercisesMC, ExercisesFB, ExercisesSAQ
  },
  data() {
    return {
      videoOptions: {},
      courseDetailArr: [],
      CourseClassify: [
        {id: 1, name: "教学目标"},
        {id: 2, name: "教学设计"},
        {id: 3, name: "电子教材"},
        {id: 4, name: "作业习题"},
        {id: 5, name: "课程微课"},
        {id: 6, name: "动画"},
        {id: 7, name: "教学视频"},
        {id: 8, name: "拓展资料"},
      ],
      enterpriseCourseArr:[
        {id: 1, name: "电子教材"},
        {id: 2, name: "课程微课"},
      ],
      courseClassfifyActive: 0,
      designWhindow: false,
      assignmentShow: 1,
      courseDetail: {},
      skus: [],
      courseDayNubmer:'',
      courseDetailData: {},
      coursemiCrolecture: [],
      resourceImg:[],
      courseDetailPage: 1,
      courseDetailSize: 5,
      courseDetailTotal: 0,
      questionList: [],
      workPage: 1,
      worksize: 5,
      worktotal: 0,
      videoWhindow:false,
      videoWhindow2:false,
      vid:'',
      playauth: '',
      chooseRadio: '选中且禁用',
      pptWhindow:false,
      pptResourceImg:[],
      coursePPtimg:'',
      activeName: 'first',
      courseActiveName:'textbook',
      teachingOutlineObj:{},
      outlineImg:'',
      myCourseDetail:'',
      newUserInfo:{},
      colloctCourceTag:true,
      closecolloctCourceTag:false,
      collectListId:[],
      count:'',
      isCollect:0,
      coursePrice:'',
      isShowBuyBtn:false,
      skuId:'',
      freeData:'',
      courseVideoId:'',
      lockIcon:false,
      enterprise:false,
      taskActiveId:'',
      freeDataValue:false,
    };
  },
  methods: {
    handleAliplayerReady(){
      const time = this.$refs.VueAliplayerV2.getDuration();
      let timeType=parseInt(time.toString())
      let data={
        avId:this.courseVideoId,
        courseId:this.courseDetail.id,
        playTotalTime:timeType
      };
      appendPlayLog(data).then(res=>{

      })
    },
    getLetter(index){
      return String.fromCharCode(65 + index)
    },

    // tab标签
    changeCourseClassify(index) {
      this.courseDetailPage=1
      this.courseClassfifyActive = index;
      this.chooseChilrden(this.courseDetailData);
      //
    },
    //企业tab
    enterpriseCourseClassify(index){
      this.courseDetailPage=1
      // this.courseClassfifyActive = index;
      this.chooseCourseResource(this.courseDetailData);
    },
    //用户章节视频列表
    getFindItemList(item,itemBig,itemChildren){
      if(itemChildren&&itemChildren.freeData==0&&this.isShowBuyBtn){
        this.$message('您还没有购买课程,请购买后再观看.');return
      }
      let data={
        courseId:this.courseDetail.id
      }
      findItemList(data).then(res=>{
        this.courseDetailArr=res.data;
        if(res.data[0].freeData==0){
          this.freeDataValue=true
        }
        res.data.forEach(item=>{
          item.courseItemVos.forEach(item1=>{
            item1.courseItemResources.forEach(item2=>{
              item2.sourceRawName=item2.sourceRawName.substr(0,item2.sourceRawName.length-4)
            })
          })
        })
        this.taskActiveId=res.data[0].courseItemVos[0].courseItemResources[0].id+''
        this.$nextTick(()=>{
          this.taskActiveId = String(res.data[0].courseItemVos[0].courseItemResources[0].id);
        })
        let that=this
        if(itemBig?itemBig.freeData==0:res.data[0].freeData==0&&!that.newUserInfo.teacherType){
          if(that.isShowBuyBtn===true){
            this.$message('您还没有购买课程,请购买后再观看.');
            document.documentElement.scrollTop=0;return
          }
        }
        if(itemBig&&itemBig.freeData==0&&!that.newUserInfo.teacherType){
          if(that.isShowBuyBtn===true){
            this.$message('您还没有购买课程,请购买后再观看.');
            document.documentElement.scrollTop=0;return
          }
        }
        this.videoWhindow2 = false;
        this.videoOptions.vid=item?item.thirdPartyStorageId:res.data[0].courseItemVos[0].courseItemResources[0].thirdPartyStorageId
        let data1 = {
          id: item?item.id:res.data[0].courseItemVos[0].courseItemResources[0].id
        }
        playVoucher(data1).then((res)=>{
          this.videoWhindow2 = true;
          this.videoOptions.playauth = res.data.playAuth
          console.log('this.videoOptions',this.videoOptions)
        })
        let query={
          platformCourseId:itemBig?itemBig.platformCourseId:res.data[0].courseItemVos[0].courseItemResources[0].platformCourseId,
          sectionNameOrVideo:itemBig?itemBig.name:res.data[0].courseItemVos[0].courseItemResources[0].sourceRawName
        }
        if(item){
          this.courseVideoId=item.id
        }else{
          this.courseVideoId=res.data[0].courseItemVos[0].courseItemResources[0].id
        }
        addRecentStudy(query).then(res=>{
        })
      })
    },
    // 显示教学设计弹窗
    designWindowShow(item,user) {
      let fileType = filePlayType(item.sourceRawName?item.sourceRawName:item.resourceName);
      if(fileType === 'avTypeAry'||fileType === 'audioTypeAry'){
        this.courseVideoId=item.id
        if(this.$route.query.myCourseDetail){
          let dataArr=[]
          let data = {
            thirdPartyStorageId: item.thirdPartyStorageId
          }
          dataArr.push(data)
          audioImg({queryDataList:dataArr}).then((res)=>{
            res.data.forEach(item1=>{
              this.videoOptions.playauth =item1.playAuth
              this.videoOptions.vid =item.thirdPartyStorageId
            })
          })
          this.videoWhindow = true;
        }else{
          this.videoOptions.vid=item.thirdPartyStorageId
          let data = {
            id: item.id
          }
          playVoucher(data).then((res)=>{
            this.videoOptions.playauth = res.data.playAuth
            if(!user){
              this.videoWhindow = true;
            }
          })
        }
      }
      if(fileType === 'imageTypeAry'){
        if(this.$route.query.myCourseDetail){
          let dataArr=[]
          let data = {
            thirdPartyStorageId:item.thirdPartyStorageId
          }
          dataArr.push(data);
          audioImg({queryDataList:dataArr}).then(res => {
            res.data.forEach(item=>{
              this.resourceImg.push(item.url);
            })
            this.outlineImg=res.data[0].url;
          });
        }else{
          this.resourceImg.push(item.sourceChangeReadUrl);
          this.outlineImg=item.sourceChangeReadUrl;
        }
        this.designWhindow = true;
      }
      if(fileType === 'officeTypeAry' || fileType === 'pdfTypeAry'){
        this.pptWhindow = true;
        if(this.$route.query.myCourseDetail){
          let dataArr=[]
          let data = {
            thirdPartyStorageId: item.thirdPartyStorageId
          }
          dataArr.push(data)
          audioImg({queryDataList:dataArr}).then(res => {
            res.data.forEach(item=>{
              this.pptResourceImg = item.aliVideoOssUrl.split(',');
              this.coursePPtimg=this.pptResourceImg[0]
            })
          });
        }else{
          this.pptResourceImg=item.sourceChangeReadUrl.split(',');
          this.coursePPtimg=this.pptResourceImg[0]
        }

      }
    },
    //查看教学大纲
    selectOutline(){
      if(!this.teachingOutlineObj) return;
      let fileType = filePlayType(this.teachingOutlineObj.sourceChangeName?this.teachingOutlineObj.sourceChangeName:this.teachingOutlineObj.resourceName);
      if(fileType === 'avTypeAry'||fileType === 'audioTypeAry'){
        let data = {
          id: this.teachingOutlineObj.id
        }
        this.videoOptions.vid=this.teachingOutlineObj.thirdPartyStorageId
        playVoucher(data).then((res)=>{
          this.videoOptions.playauth = res.data.playAuth
          this.videoWhindow = true;
        })
      }
      if(fileType === 'imageTypeAry'){
        if(this.$route.query.myCourseDetail){
          let dataArr=[]
          let data = {
            thirdPartyStorageId: this.teachingOutlineObj.thirdPartyStorageId
          }
          dataArr.push(data)
          audioImg({queryDataList:dataArr}).then(res => {
            res.data.forEach(item=>{
              this.resourceImg.push(item.url);
            })
            this.outlineImg=res.data[0].url
          });
        }else{
          this.resourceImg.push(this.teachingOutlineObj.sourceChangeReadUrl);
          this.outlineImg=this.teachingOutlineObj.sourceChangeReadUrl;
        }
        this.designWhindow = true;
      }
      if(fileType === 'officeTypeAry'||fileType === 'pdfTypeAry'){
        if(this.$route.query.myCourseDetail){
          let dataArr=[]
          let data = {
            thirdPartyStorageId: this.teachingOutlineObj.thirdPartyStorageId
          }
          dataArr.push(data)
          audioImg({queryDataList:dataArr}).then(res => {
            res.data.forEach(item=>{
              this.pptResourceImg = item.aliVideoOssUrl.split(',');
              this.coursePPtimg=this.pptResourceImg[0]
            })
          });
        }else{
          this.pptResourceImg=this.teachingOutlineObj.sourceChangeReadUrl.split(',');
          this.coursePPtimg=this.pptResourceImg[0]
        }
        this.pptWhindow = true;
      }
    },
    // 习题或作业
    changeAssignment(index) {
      this.workPage=1
      this.assignmentShow = index;
      this.getExercises()
    },
    //  教师获取课程详情树形结构
    async getCourseDetailClassify() {
      let courseItemList = this.courseDetail.courseItemList
      const listToTree = (listArr, pid) => {
        let tree = [];
        listArr.forEach((item, index) => {
          if (item.pid === pid) {
            let child = listToTree(courseItemList, item.id)
            let data = {...item}
            child && child.length && (data['children'] = child)
            tree.push(data)
          }
        })
        return tree;
      }
      this.courseDetailArr = listToTree(courseItemList, '0');
      // if(this.enterprise){
      //   this.taskActiveId=this.courseDetailArr[0].children[0].id+''
      //   this.$nextTick(()=>{
      //     this.taskActiveId = String(this.courseDetailArr[0].children[0].id);
      //   })
      //   this.chooseCourseResource(this.courseDetailArr[0].children[0]);
      // }
      await this.handleClick();
    },
    //企业课程资源
    chooseCourseResource(item){
      this.freeData=item.freeData
      if (!Object.keys(item).length) return;
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      this.courseDetailData = item;
      sessionStorage.setItem('courseDetailData',JSON.stringify(item))
      let data = {
        platformCourseId: item.platformCourseId,
        platformCourseItemId: item.id,
        page: this.courseDetailPage,
        size: this.courseDetailSize
      }
      if(this.courseActiveName=='textbook'){
        data['sourceDiff'] = 'Ebook'
      }else{
        data['sourceDiff'] = 'MicroLecture'
      }
      getResourceListApi(data).then(res =>{
        this.coursemiCrolecture = res.data.records;
        this.courseDetailTotal = JSON.parse(res.data.total);
      })
    },
    //用户获取微课列表
    userChooseChildren(item){



    },
    //课程资源
    chooseChilrden(item) {
      this.freeData=item.freeData
      if (!Object.keys(item).length) return;
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      this.courseDetailData = item;
      sessionStorage.setItem('courseDetailData',JSON.stringify(item))
      let data = {
        platformCourseId: item.platformCourseId,
        platformCourseItemId: item.id,
        page: this.courseDetailPage,
        size: this.courseDetailSize
      }
      let map = {
        0:'Objectives',
        1: 'TeacherDoc',
        2: 'Ebook',
        3: 'courseExercises',
        4: 'MicroLecture',
        5: 'Animation',
        6: 'Teaching',
        7:'Other'
      }
      let Mymap = {
        0:'Objectives',
        1: 'TeacherDoc',
        2: 'Ebook',
        3: 'courseExercises',
        4: 'MicroLecture',
        5: 'Animation',
        6: 'Teaching',
        7:'Other'
      }
      if(this.$route.query.myCourseDetail){
        data['sourceDiff'] = Mymap[this.courseClassfifyActive];
      }else{
        data['sourceDiff'] = map[this.courseClassfifyActive]
      }
      if(this.$route.query.myCourseDetail){
        data['teacherId']=userInfo.id;
      }
      if(this.$route.query.myCourseDetail){
        selectAddTeacherResourceList(data).then(res=>{
          if(this.courseClassfifyActive === 3){
            this.questionList = res.data.records;
            this.courseDetailTotal = JSON.parse(res.data.total);
            this.questionList.forEach((item, index) => {
              if(item.customText){
                let customTextNew=JSON.parse(item.customText)
                this.$set(this.questionList[index],  "exercisesTitle",customTextNew.exercisesTitle);
                this.$set(this.questionList[index],  "questionOption",customTextNew.exercisesBody.split('\@'));
                this.$set(this.questionList[index],  "correctAnswer",customTextNew.correctAnswer.split(','));
                if(customTextNew.exercisesType=='FB'){
                  this.$set(this.questionList[index],  "exercisesBody",customTextNew.exercisesBody.split('\@'));
                }
                if(customTextNew.correctAnswer){
                  this.$set(this.questionList[index],  "correctAnswer",customTextNew.correctAnswer.split(','));
                }
                this.$set(this.questionList[index],  "analysis",customTextNew.analysis);
                item.customText=JSON.parse(item.customText)
              }
            })
          }else{
            this.coursemiCrolecture=res.data.records;
            this.courseDetailTotal = JSON.parse(res.data.total);
          }
        })
      }else{
        getResourceListApi(data).then(res =>{
          this.coursemiCrolecture = res.data.records;
          this.courseDetailTotal = JSON.parse(res.data.total);
        })
        if (this.courseClassfifyActive === 3) {
          this.getExercises()
        }
      }
      let query={
        platformCourseId:item.platformCourseId,
        sectionNameOrVideo:item.name
      }
      addRecentStudy(query).then(res=>{
      })
    },
    //选择章
    // chooseChapter(item){
    //   if (!Object.keys(item).length) return;
    //   this.courseDetailData = item
    //   let data = {
    //     platformCourseId: item.platformCourseId,
    //     platformCourseItemId: item.id,
    //     page: this.courseDetailPage,
    //     size: this.courseDetailSize
    //   }
    //   let map = {
    //     0:'Objectives',
    //     1: 'TeacherDoc',
    //     2: 'Ebook',
    //     3: 'Other',
    //     4: 'MicroLecture',
    //     5: 'Animation',
    //     6: 'Teaching',
    //   }
    //
    //   data['sourceDiff'] = map[this.courseClassfifyActive]
    //   getResourceList(data).then(res => {
    //     // if (res.data.records && res.data.records.length) {
    //     this.coursemiCrolecture = res.data.records;
    //     this.courseDetailTotal = JSON.parse(res.data.total);
    //     // }
    //   })
    //   if (this.courseClassfifyActive === 7) {
    //     this.getExercises()
    //   }
    // },
    // 获取习题作业
    getExercises(){
      let query = {
        platformCourseId: this.courseDetailData.platformCourseId,
        platformCourseItemId: this.courseDetailData.id,
        page: this.workPage,
        size: this.worksize,
        privateTeacherData:0
      }
      if(this.assignmentShow==1){
        query['sourceDiff']='1'
      }
      if(this.assignmentShow==2){
        query['sourceDiff']='2'
      }
      operationTopic(query).then(res => {
        this.worktotal = JSON.parse(res.data.total);
        res.data.records.forEach((item, index) => {
          if(item.exercisesBody){
            item.questionOption = item.exercisesBody.split('\@')
          }
          if(item.correctAnswer){
            item.correctAnswerArr = item.correctAnswer.split(',')
          }
          if(item.exercisesType=='FB'){
            item.exercisesBody=JSON.parse(item.exercisesBody)
          }
        })
        this.questionList = res.data.records;
      })
    },
    //  教学设计分页
    handleDesign(courseDetailPage) {
      this.courseDetailPage = courseDetailPage;
      this.chooseChilrden(this.courseDetailData);
    },
    //企业教学设计分页
    handleDesignresource(courseDetailPage){
      this.courseDetailPage = courseDetailPage;
      this.chooseCourseResource(this.courseDetailData);
    },
    //  习题作业分页
    handleworkList(workPage) {
      this.workPage = workPage;
      this.chooseChilrden(this.courseDetailData);
    },
  //  点击小图切换大图
    changePPtImg(item){
      this.coursePPtimg=item
    },
  //  tab
     handleClick(){
      if(this.activeName=='first'){
        let courseDetail = JSON.parse(sessionStorage.getItem('courseDetail'))
        let data={
          platformCourseId: courseDetail.id,
          syllabusData:1
        };
        getResourceListApi(data).then(res=>{
          this.teachingOutlineObj=res.data.records[0]
        })
      }else{
        let obj={
          platformCourseId:this.courseDetailArr[0].children[0].platformCourseId,
          id:this.courseDetailArr[0].children[0].id,
          name:this.courseDetailArr[0].children[0].name,
        }
        if(!this.enterprise){
          if(this.newUserInfo&&this.newUserInfo.teacherType){
            this.chooseChilrden(obj)
          }
          if(this.newUserInfo&&this.newUserInfo.teacherType){
            this.taskActiveId=this.courseDetailArr[0].children[0].id+''
            this.$nextTick(()=>{
              this.taskActiveId = String(this.courseDetailArr[0].children[0].id);
            })
          }
        }else{
          this.chooseCourseResource(obj)
        }
      }
    },
  //  个人课程教学大纲
    getmyCourceDg(){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data = {
        platformCourseId: this.courseDetail.id,
        teacherId:userInfo.id,
        syllabusData:'1'
      }
      selectAddTeacherResourceList(data).then(res=>{
        this.teachingOutlineObj=res.data.records[0];
      })
    },
  //  收藏课程
    collectCource(){
      let courseDetail = JSON.parse(sessionStorage.getItem('courseDetail'))
      let data={
        courseId:courseDetail.id
      };
      studentcollectCource(data).then(res=>{
        if(res.code==0){
          this.$message({
            message: "收藏成功",
            type: "success",
          });
          this.courseDetail.whetherCollection=1
          sessionStorage.setItem('courseDetail',JSON.stringify(this.courseDetail) )
          // this.isCollect=1;
        }else{
          this.$message.error(res.msg)
        }
      })
    },
  //  取消收藏课程
    stdCancleCollectCource(){
      let courseDetail = JSON.parse(sessionStorage.getItem('courseDetail'))
      let data={
        courseId:courseDetail.id
      };
      cancleCollectCource(data).then(res=>{
        if(res.code==0){
          this.$message({
            message: "取消收藏成功",
            type: "success",
          });
          this.courseDetail.whetherCollection=0
          sessionStorage.setItem('courseDetail',JSON.stringify(this.courseDetail) )
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    //查询收藏课程Id集合
    getselectCollectCourceId(){
      let courseDetail = JSON.parse(sessionStorage.getItem('courseDetail'))
      let data={
        courseId:courseDetail.id
      };
      selectCollectCourceId(data).then(res => {
        res.data.records.forEach(item=>{
          if(item.id==courseDetail.id){
            this.isCollect=1
            console.log(this.isCollect)
          }else{
            this.isCollect=0
          }
        })
        this.$forceUpdate()
      })
      //this.collectListId=res.data
    },
  //  点击天数改变钱数
    changeCourseDay(courPrice){
      this.coursePrice=courPrice
    },
  //  立即购买
    promptlyBuy(item){
      let skuIdData=""
      this.courseDetail.skus.forEach(item1=>{
        if(item1.validDays+'天'==this.courseDayNubmer){
          skuIdData=item1.id;
        }else if(item1.price==this.courseDayNubmer){
          skuIdData=item1.id;
        }
      })
      let routeUrl = this.$router.resolve({
        path: "/courseOrder",
        query:{payNumber:item.courseActivityVo?(this.coursePrice*item.courseActivityVo.price).toFixed(2):this.coursePrice,originalPrice:this.coursePrice,skuId:skuIdData}
      });
      window.open(routeUrl.href);
    },
  //  查询用户是够购买课程
    getUserBuyCourse(){
      let data={
        courseId:this.courseDetail.id
      };
      checkUserPayCourse(data).then(res=>{
        if(res.code==0){
          this.isShowBuyBtn=false
        }else{
          this.isShowBuyBtn=true
        }
      })
    },
    //获取课程列表
    getCourseList() {
      let data={
        page:1,
        size:50,
        schoolId:this.newUserInfo?this.newUserInfo.schoolId:''
      }
      courseList(data).then(res => {
        if(res.code==0&&res.data.records){
          res.data.records.forEach(item=>{
            if(item.id==this.courseDetail.id){
              this.lockIcon=true
            }
          })
        }
      })
    },
    //分享
    share(){
        // 存储传递过来的数据
        let OrderNumber = 'http://exiaozhi.sdzjds.com/';
        // 创建一个input 元素
        // createElement() 方法通过指定名称创建一个元素
        let newInput = document.createElement("input");
        // 讲存储的数据赋值给input的value值
        newInput.value = OrderNumber;
        // appendChild() 方法向节点添加最后一个子节点。
        document.body.appendChild(newInput);
        // 选中input元素中的文本
        // select() 方法用于选择该元素中的文本。
        newInput.select();
        // 执行浏览器复制命令
        //  execCommand方法是执行一个对当前文档，当前选择或者给出范围的命令
        document.execCommand("Copy");
        // 清空输入框
        newInput.remove();
        // 下面是element的弹窗 不需要的自行删除就好
        this.$message({
          message: "复制链接成功,请粘贴.",
          type: "success",
        });
      }
  },
  created(){
    if(this.$route.query.enterprise){
      this.enterprise=true
    }
  },
  watch:{
  },

  mounted() {
    let courseDetail = sessionStorage.getItem('courseDetail')
    this.courseDetail = JSON.parse(courseDetail);
    document.documentElement.scrollTop = 0;
    this.$other.$emit('setIndexNav',-1);
    this.getselectCollectCourceId()
    this.newUserInfo=JSON.parse(localStorage.getItem("userInfo")) ;
    if(this.newUserInfo&&!this.newUserInfo.teacherType){
      this.activeName='second'
      this.getFindItemList()
    }
    this.myCourseDetail=this.$route.query.myCourseDetail

    this.getCourseList()
    // this.skus = this.courseDetail.skus;
    if(this.courseDetail.skus.length){
      let arr=[]
      this.courseDayNubmer=this.courseDetail.skus[0].validDays+'天';
      this.coursePrice=this.courseDetail.skus[0].price;
      this.courseDetail.skus.forEach(item=>{
        if(item.platformType=='web'){
          arr.push(item)
        }
      })
      this.skus=arr;
    }
    this.getCourseDetailClassify();

    if(this.$route.query.myCourseDetail){
      this.getmyCourceDg()
    }
    if(this.$route.query.count){
      this.count=this.$route.query.count
    }

    this.getUserBuyCourse();

  },
};
</script>
<style scoped>
@import "../../../public/css/courseDetail.css";
@import "../../../public/css/modifyPaper.css";
</style>
