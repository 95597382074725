<template>
  <div class="prepareCourseCenterBg">
    <div class="prepareCourseWd">
      <div class="prepareCourseBackGround">
        <div>
          <img src="images/prepare4.png">
          <div class="prepareCenterMOre">
            <p class="prepareCenterTitle">备课中心</p>
            <p class="prepareCenterMoreCls" @click="goPrepareCourseCenter">MORE>></p>
          </div>
        </div>
        <div>
          <div>
            <img src="images/prepare2.png" class="prepareImgCenter">
            <div class="prepareCenterInteraction">
              <p class="prepareCenterTitle">互动课堂 </p>
              <p>（直播课堂,互动课堂）</p>
              <p class="prepareCenterMoreCls" @click="goCourserStreaming()">MORE>></p>
            </div>
          </div>
          <div>
            <img src="images/prepare3.png">
            <div class="prepareCenterCourse">
              <p class="prepareCenterTitle">课程详情</p>
              <p class="prepareCenterMoreCls" @click="goCourserDetail()">MORE>></p>
            </div>
          </div>
        </div>
        <div>
          <img src="images/prepare1.png">
          <div class="prepareCenterClass">
            <p class="prepareCenterTitle2" v-if="className">{{ className }}</p>
            <div v-if="teacherCourse" class="selectClassCourse">
              <p class="selectClassCourseP">选择班级:</p>
              <el-select v-model="classValue" placeholder="请选择上课班级">
                <el-option
                    v-for="item in classAdministrationData"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name">
                </el-option>
              </el-select>
            </div>
            <p class="prepareCenterMoreCls3" @click="goPrepareCourse">MORE>></p>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="courseShow">
      <div class="blackHeight">
        <div class="blackboard">
          <img src="images/palette.png">
          <div class="blackPosition">
            <el-menu
                text-color="#fff"
                active-text-color="#49B151"
                :default-active="activeId"
                class="el-menu-vertical-demo"

            >
              <el-submenu v-for="(item, index) in courseDetailArr"
                          :key="item.id" :index="item.id">
                <template slot="title">
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item-group
                    v-for="(itemChildren, indexChildren) in item.children"
                    :key="indexChildren"
                >
                  <el-menu-item :index="itemChildren.id">
                    <div slot="title" @click="chooseChilrden(itemChildren)">
                      <span class="jieTitle">{{ itemChildren.name }}</span>
                      <!--        -->
                      <span v-if="activeId&&itemChildren.id==activeId">(已经讲到此处了)</span>
                      <!-- <span class="trySee">试看</span> -->
                      <!--                      <img v-if="itemChildren.freeData==0" src="images/lock.png" alt="" class="lock"/>-->
                    </div>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {classList, selectLoginTeacherResourceList} from "@/api";

export default {
  data() {
    return {
      activeId: '',
      courseShow: false,
      courseDetail: {},
      courseDetailArr: [],
      courseItemList: [],
      className: '',
      classAdministrationData: [],
      classValue: '',
      classId: '',
      teacherCourse:''
    }
  },
  computed: {

  },
  methods: {
    //  去备课中心
    goPrepareCourseCenter() {
      if (this.$route.query.teacherCourse) {
        this.$router.push({path: 'prepareCurriculum', query: {teacherCourse: 'teacherCourse'}})
      } else {
        this.$router.push('prepareCurriculum')
      }
    },
    //课程详情
    goCourserDetail() {
      if(this.$route.query.teacherCourse){
        let routeUrl = this.$router.resolve({
          path: "/courseDetail",
          query:{
            myCourseDetail:'myCourseDetail'
          }
        });
        window.open(routeUrl.href);
      }else{
        let routeUrl = this.$router.resolve({
          path: "/courseDetail",
        });
        window.open(routeUrl.href);
      }
    },
    //  直播
    goCourserStreaming() {
      this.$message('功能正在开发中,敬请期待...')
    },
    //去上课
    goPrepareCourse() {
      this.getLoginTeacherResourceList();
      this.getCourseDetailClassify();
      this.courseShow = true;
    },
    //  获取课程详情树形结构
    getCourseDetailClassify() {
      let courseItemList = this.courseItemList
      const listToTree = (listArr, pid) => {
        let tree = [];
        listArr.forEach((item, index) => {
          if (item.pid === pid) {
            let child = listToTree(courseItemList, item.id)
            let data = {...item}
            child && child.length && (data['children'] = child)
            tree.push(data)
          }
        })
        return tree;
      }
      this.courseDetailArr = listToTree(courseItemList, '0')
    },
    //课程资源
    chooseChilrden(item) {
      this.classAdministrationData.forEach(item => {
        if (item.name == this.classValue) {
          this.classId = item.id
        }
      })
      sessionStorage.setItem('courseResourceDetail', JSON.stringify(item))
      this.$router.push({
        path: 'speakCourse',
        query: {
          progressId: this.$route.query.progressId,
          className: this.$route.query.teacherCourse?this.classValue:this.$route.query.className,
          classId: this.courseDetail.ownerType == 'platform' ? this.$route.query.classId : this.classId
        }
      })
    },
    //  获取登陆老师章节信息
    getLoginTeacherResourceList() {
      this.activeId = ''
      this.$forceUpdate()
      if(this.$route.query.teacherCourse){
        this.classAdministrationData.forEach(item => {
          if (item.name == this.classValue) {
            this.classId = item.id
          }
        })
      }else{
        this.classId =this.$route.query.classId
      }
      let data = {
        courseId: this.courseDetail.id,
        classId: this.classId ? this.classId : this.$route.query.classId
      };
      selectLoginTeacherResourceList(data).then(res => {
        res.data.forEach((item) => {
          item.isLastLecture && (this.activeId = String(item.id))
        })
        this.courseItemList = res.data
        this.$forceUpdate()
      })
    },
    //  获取班级列表
    getClassList() {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data = {
        schoolId: userInfo.schoolId,
        page: 1,
        size: 50
      }
      classList(data).then(res => {
        if (res.data.records) {
          this.classAdministrationData = res.data.records;
          this.classValue = res.data.records[0].name
        }
      })
    },
  },
  mounted() {
    let courseDetail = sessionStorage.getItem('courseDetail')
    this.courseDetail = JSON.parse(courseDetail);
    if (this.$route.query.className) {
      this.className = this.$route.query.className
    };
    if(this.$route.query.teacherCourse){
      this.teacherCourse=this.$route.query.teacherCourse
    }
    this.getClassList();
    this.getLoginTeacherResourceList();
  }
}
</script>
<style scoped>
@import "../../../public/css/prepareCourseCenter.css";
@import "../../../public/css/courseDetail.css";
</style>
