<template>
  <div class="checkPaperListBg">
    <div class="checkPaperCenter">
      <div class="checkPaperLeft" v-if="workDetail&&Object.keys(workDetail).length">
        <p class="checkPaperTitle">
          <span>作业标题：</span>
          <span>{{workDetail.name}}</span>
        </p>
        <p class="subject">
          <span>所属科目：</span>
          <span>{{workDetail.platformCourseName}}</span>
        </p>
        <p class="subject">
          <span>参加人数：</span>
          <span>{{parseInt(workDetail.finisheNumber)+parseInt(workDetail.unfinishedNumber)}}人</span>
        </p>
        <p class="subject">
          <span>完成人数：</span>
          <span>{{workDetail.finisheNumber}}人</span>
        </p>
<!--        <p class="synopsis">作业简介：</p>-->
<!--        <p class="synopsisContent">-->
<!--          甲午战争失败标志着清朝历时三十余年的洋务运动的失败，取得的近代化成果化为乌有，打破了近代以来中国人民对民族复兴的追求。割地赔款，主权沦丧，便利列强对出资本，掀起瓜分狂潮,标志着列强侵华进入了一个新阶段，大大加深了中国的半殖民地化，中国的国际地位急剧下降。-->
<!--        </p>-->
<!--        <p class="synopsis">附件：</p>-->
<!--        <p></p>-->
      </div>
      <div class="checkPaperLeft" v-if="examDetail&&Object.keys(examDetail).length">
        <p class="checkPaperTitle">
          <span>试卷标题：</span>
          <span>{{examDetail.name}}</span>
        </p>
        <p class="subject">
          <span>未完成数：</span>
          <span>{{examDetail.unfinishedNumber}}人</span>
        </p>
        <p class="subject">
          <span>已完成数：</span>
          <span>{{examDetail.finishedNumber}}人</span>
        </p>
        <p class="subject">
          <span>总分：</span>
          <span>{{examDetail.totalScore}}分</span>
        </p>
      </div>
      <div class="checkPaperRight">
        <div class="correctFlex">
          <p
            class="correctTab"
            :class="correctStateShow == index ? 'correctStateActive' : ''"
            @click="changecorrectState(index)"
            v-for="(item, index) in correctStateTab"
            :key="index"
          >
            {{ item.name }}
          </p>
        </div>
        <div class="measurementTable" v-if="this.$route.query.type==='work'">
          <el-table :data="workStdList" style="width: 100%" height="500">
            <el-table-column prop="index" type="index" :index="indexMethod" label="序号 "width="80"> </el-table-column>
            <el-table-column prop="name" label="姓名" width="160">
              <template slot-scope="scope">
                <p>{{ scope.row.name?scope.row.name:scope.row.telNumber }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="studentNumber" label="学号" width="160">
              <template slot-scope="scope">
                <p>{{ scope.row.studentNumber}}</p>
              </template></el-table-column>
            <el-table-column prop="submitTime" label="提交时间" width="160">
            </el-table-column>
            <el-table-column prop="classWorkType" label="状态" width="120">
              <template slot-scope="scope">
                <p v-if="scope.row.classWorkType=='NR'">未批阅</p>
                <p v-if="scope.row.classWorkType=='AR'">已批阅</p>
                <p v-if="scope.row.classWorkType=='TBC'">未提交</p>
              </template>
            </el-table-column>
            <el-table-column prop="operation" label="评分" width="155">
              <template slot-scope="scope">
                <p v-if="scope.row.classWorkType=='NR'||scope.row.classWorkType=='TBC'" class="opesrations" @click="goModifyPaper(scope.row)">操作</p>
                <p v-if="scope.row.classWorkType=='AR'&&scope.row.classWorkGrade=='A'" @click="goModifyPaper(scope.row)" class="opesrations">优</p>
                <p v-if="scope.row.classWorkType=='AR'&&scope.row.classWorkGrade=='B'" @click="goModifyPaper(scope.row)" class="opesrations">良</p>
                <p v-if="scope.row.classWorkType=='AR'&&scope.row.classWorkGrade=='C'" @click="goModifyPaper(scope.row)" class="opesrations">及格</p>
                <p v-if="scope.row.classWorkType=='AR'&&scope.row.classWorkGrade=='D'" @click="goModifyPaper(scope.row)" class="opesrations">不及格</p>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top:50px" >
            <el-pagination
                class="text_center"
                background
                @current-change="handleWorkStdList"
                :current-page.sync="workPage"
                :page-size="workSize"
                layout="total,  prev, pager, next"
                :total="workTotal"
            >
            </el-pagination>
          </div>
        </div>
        <div class="measurementTable" v-if="this.$route.query.type==='exam'">
          <el-table :data="examDetailArr" style="width: 100%" height="500">
            <el-table-column prop="index" type="index" :index="indexMethod1" label="序号 " width="80"> </el-table-column>
            <el-table-column prop="name" label="姓名" width="160">
              <template slot-scope="scope">
                <p>{{ scope.row.examPapersStudentInfo.userName?scope.row.examPapersStudentInfo.userName:scope.row.examPapersStudentInfo.userTelNumber }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="examPapersStudentInfo.studentNumber" label="学号" width="160">
              <template slot-scope="scope">
                <p>{{ scope.row.studentNumber}}</p>
              </template>
            </el-table-column>
            <el-table-column prop="examPapersStudentInfo.answerEndTime" label="提交时间" width="160">
            </el-table-column>
            <el-table-column prop="classWorkType" label="状态" width="120">
              <template slot-scope="scope">
                <p v-if="scope.row.examPapersStudentInfo.progressType=='NR'">未批阅</p>
                <p v-if="scope.row.examPapersStudentInfo.progressType=='AR'">已批阅</p>
                <p v-if="scope.row.examPapersStudentInfo.progressType=='TBC'">未提交</p>
              </template>
            </el-table-column>
            <el-table-column prop="operation" label="评分" width="155">
              <template slot-scope="scope">
                <p v-if="scope.row.examPapersStudentInfo.progressType=='NR'||scope.row.examPapersStudentInfo.progressType=='TBC'" class="opesrations" @click="goExamModifyPaper(scope.row)">操作</p>
                <p v-if="scope.row.examPapersStudentInfo.progressType=='AR'" @click="goExamModifyPaper(scope.row)" class="opesrations">{{scope.row.examPapersStudentInfo.totalScore}}分</p>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top:50px" >
            <el-pagination
                class="text_center"
                background
                @current-change="handleExamStdList"
                :current-page.sync="examPage"
                :page-size="examSize"
                layout="total,  prev, pager, next"
                :total="examTotal"
            >
            </el-pagination>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {workStudentList,teacherCheckExaminationStdList} from "@/api";
export default {
  data() {
    return {
      workPage:1,
      workSize:10,
      workTotal:0,
      correctStateShow: 0,
      correctStateTab: [
        { id: 1, name: "全部" },
        { id: 2, name: "已批阅" },
        { id: 3, name: "未批阅" },
        { id: 4, name: "未提交" },
      ],
      workId:"",
      workStdList:[],
      workDetail:{},
      examId:'',
      examDetail:{},
      examDetailArr:[],
      examPage:1,
      examSize:10,
      examTotal:0
    };
  },
  methods: {
    indexMethod(index) {
      return index + (this.workPage * this.workSize) -9 ;
    },
    indexMethod1(index) {
      return index + (this.examPage * this.examSize) -9;
    },
    // 批改测试列表tab切换
    changecorrectState(index) {
      this.correctStateShow = index;
      if(this.$route.query.type==='work'){
        this.getWorkSteList()
      }else{
        this.getTeacherCheckExaminationStdList()
      }
    },
    // 批阅
    goModifyPaper(item){
      sessionStorage.setItem('studentWorkDetail',JSON.stringify(item) )
      this.$router.push({path:'modifyPaper',query:{courseworkId:this.workId,queryStudentId:item.id,progressType:item.classWorkType}})
    },
    //去批阅试卷
    goExamModifyPaper(item){
      sessionStorage.setItem('studentExamDetail',JSON.stringify(item) )
      this.$router.push({path:'modifyPaper',query:{courseexamId:this.examId,queryExamStudentId:item.examPapersStudentInfo.userId,totalScore:item.examPapersStudentInfo.totalScore,progressType:item.examPapersStudentInfo.progressType}})
    },
    //获取作业学生列表
    getWorkSteList(){
      let data={
        id:this.workId,
        page:this.workPage,
        size:this.workSize
      }
      if(this.correctStateShow==1){
        data['queryStudentProgressType']="AR"
      }
      if(this.correctStateShow==2){
        data['queryStudentProgressType']="NR"
      }
      if(this.correctStateShow==3){
        data['queryStudentProgressType']="TBC"
      }
      workStudentList(data).then(res=>{
        if(res.code==0){
          this.workStdList=res.data.records;
          this.workTotal=parseInt(res.data.total)
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    //获取教师批阅试卷学生列表
    getTeacherCheckExaminationStdList(){
      let data={
        examPapersTestCenterId:this.examId,
        page:this.examPage,
        size:this.examSize
      };
      if(this.correctStateShow==1){
        data['progressType']="AR"
      }
      if(this.correctStateShow==2){
        data['progressType']="NR"
      }
      if(this.correctStateShow==3){
        data['progressType']="TBC"
      }
      teacherCheckExaminationStdList(data).then(res=>{
        this.examDetailArr=res.data.records;
        this.examTotal= parseInt(res.data.total)
      })
    },
    handleWorkStdList(workPage){
      this.workPage = workPage;
      this.getWorkSteList()
    },
  //  考试分页
    handleExamStdList(examPage){
      this.examPage=examPage;
      this.getTeacherCheckExaminationStdList()
    }
  },
  mounted() {
    this.$other.$emit('setIndexNav',1);
    document.documentElement.scrollTop = 0;
    if(this.$route.query.type==='work'){
      this.workId=this.$route.query.id;
      this.getWorkSteList()
      this.workDetail=JSON.parse(sessionStorage.getItem('workDetail'))
    }
    if(this.$route.query.type==='exam'){
      this.examId=this.$route.query.id;
      this.getTeacherCheckExaminationStdList();
      this.examDetail=JSON.parse(sessionStorage.getItem('examDetail'))
    }
  },
  watch: {
  }
};
</script>
<style scoped>
@import "../../public/css/checkPaperList.css";
</style>
