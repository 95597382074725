<template>
  <div class="prepareCurriculumBg">
    <div class="prepareCurriculumWd">
      <div class="prepareCurriculumFlex">
        <div>
          <el-menu
              default-active="1"
              class="el-menu-vertical-demo">
            <el-submenu v-for="(item, index) in courseDetailArr"
                        :key="index" :index="index + 1 + ''">
              <template slot="title">
                <span class="courseZjWid">{{ item.name }}</span>
              </template>
              <el-menu-item-group
                  v-for="(itemChildren, indexChildren) in item.children"
                  :key="indexChildren"
              >
                <el-menu-item :index="index + '-' + indexChildren">
                  <div slot="title" @click="chooseChilrden(itemChildren)">
                    <span class="courseZjWid" >{{ itemChildren.name }}</span>
                    <!-- <span class="trySee">试看</span> -->
<!--                    <img v-if="itemChildren.freeData==0" src="images/lock.png" alt="" class="lock"/>-->
                  </div>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </div>
        <div class="prepareCurriculumContent">
<!--          备课-->
          <div>
            <div class="prepareRegionFlex">
              <div>
                <span>我的备课</span>
                <span>个人素材或平台素材点选添加后加入</span>
              </div>
              <el-button type="primary" @click="publishPrepare" size="small" plain>完成备课</el-button>
            </div>
<!--            教师已选备课资源-->
            <div>
              <div class="choosePrepareChange">
                <div class="intendmaterialFlex">
                  <p v-for="(item,index) in prepareMaterialArr" :key="index" @click="changeIntendResource(index)"  :class="intendResourceShow==index?'materialResourceActive':'materialResourcep'">{{item.name}}</p>
                </div>
                <div  class="choosePrepareDiv" v-for="(item,index) in teacheroptPrepareArr" :key="index">
                  <div>
                    <p></p>
                    <p v-if="item.resourceType==1">平台</p>
                    <p v-if="item.resourceType==2">个人</p>
                  </div>
                  <div >
                    <p v-if="item.resourceName">{{ item.resourceName}}</p>
                    <p v-if="!item.resourceName">{{item.sourceRawName?item.sourceRawName:item.exercisesTitle }}</p>
                    <p class="fileOriginalName" v-if="item.fileOriginalName">{{item.fileOriginalName}}</p>
                    <div class="resourceOperation">
                      <el-button @click="selectResourceWindow(item)" size="small">查看</el-button>
                      <el-button @click="deleteResourceListIndex(item)" size="small">删除</el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-top: 30px">
              <el-pagination
                  class="text_center"
                  background
                  @current-change="handleCurrentChange"
                  :current-page.sync="teacheroptPreparePage"
                  :page-size="teacheroptPrepareSize"
                  layout="total,  prev, pager, next, jumper"
                  :total="teacheroptPrepareTotal"
              >
              </el-pagination>
            </div>
          </div>
<!--          选课-->
          <div>
            <div class="materialFlexs" v-if="!teacherCourse">
              <div>
                <p v-for="(item,index) in materialArr" @click="changeMaterialTab(index)" :key="index" :class="materialShow==index?'materialActive':'materialFlexsp'">{{item.name}}</p>
            </div>
<!--              <div class="materialSearchFlex">-->
<!--                <el-input placeholder="请输入名称"></el-input>-->
<!--                <el-button type="success">搜索</el-button>-->
<!--              </div>-->
            </div>
            <div class="materialResourceFlex" v-if="materialShow==0">
              <div class="materialFlex">
                <p v-for="(item,index) in prepareMaterialArr" :key="index" @click="changeMaterialresource(index)"  :class="materialResourceShow==index?'materialResourceActive':'materialResourceFlexsp'">{{item.name}}</p>
              </div>
            </div>
<!--            备课选择区域-->
            <div>
              <div class="choosePrepareChange" v-if="materialShow==0">
                <div class="choosePrepareDiv" v-for="(item,index) in coursemiCrolecture" :key="index">
                  <div>
                    <p></p>
                    <p>平台</p>
                  </div>
                  <div>
                    <p>{{item.sourceRawName?item.sourceRawName:item.exercisesTitle}}</p>
                    <div class="resourceOperation">
                      <el-button @click="selectResource(item)" size="small">查看</el-button>
                      <el-button @click="addSetoutCourse(item)" size="small">添加</el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="materialResourceShow==3&&materialShow==0" style="margin-top: 30px">
                <el-pagination
                    class="text_center"
                    background
                    @current-change="handleExercisesChange"
                    :current-page.sync="courseDetailPage"
                    :page-size="courseDetailSize"
                    layout="total,  prev, pager, next, jumper"
                    :total="courseDetailTotal"
                >
                </el-pagination>
              </div>
              <div v-if="materialResourceShow!==3&&materialShow==0" style="margin-top: 30px">
                <el-pagination
                    class="text_center"
                    background
                    @current-change="handlePrepareChange"
                    :current-page.sync="teacheroptPreparePage1"
                    :page-size="teacheroptPrepareSize1"
                    layout="total,  prev, pager, next, jumper"
                    :total="teacheroptPrepareTotal1"
                >
                </el-pagination>
              </div>
            </div>
            <div class="materialResourceFlex" v-if="materialShow==1">
              <div class="materialFlex">
                <p v-for="(item,index) in personageMaterialArr" :key="index" @click="changePersonageResource(index)"  :class="personageResourceShow==index?'materialResourceActive':'materialResourceFlexsp'">{{item.name}}</p>
              </div>
            </div>
            <div class="uploadingPrepare" v-if="materialShow==1&&personageResourceShow==0">
              <documentAdministrator @selectFeilItem="selectFeilItem(arguments)"></documentAdministrator>
            </div>
            <div class="uploadingPrepare" v-if="materialShow==1&&personageResourceShow==1">
              <qList @selectQuestionItem="selectQuestionItem(arguments)"></qList>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    教师已备课习题弹窗-->
    <el-dialog :visible.sync="exercisesSetoutShow" :show-close="true">
      <div v-if="item.customText" v-for="(item,index) in teacheroptPrepareArr" :key="index">
        <exercises-s-c
            ref="std"
            v-if="item.customText.exercisesType === 'SC'"
            :exercisesInfo="item.customText"
            :openType="2"
            :isShowRightAndAnalysis="true"
        ></exercises-s-c>
        <exercises-m-c
            ref="std"
            v-if="item.customText.exercisesType === 'MC'"
            :exercisesInfo="item.customText"
            :openType="2"
            :isShowRightAndAnalysis="true"
        ></exercises-m-c>
        <!-- 填空题 -->
        <exercises-f-b
            ref="std"
            v-if="item.customText.exercisesType === 'FB'"
            :exercisesInfo="item.customText"
            :openType="2"
            :isShowRightAndAnalysis="true"
        >
        </exercises-f-b>
        <!-- 判断题 -->
        <exercises-t-f
            ref="std"
            v-if="item.customText.exercisesType=== 'TF'"
            :exercises-info="item.customText"
            :open-type="2"
            :isShowRightAndAnalysis="true"
        ></exercises-t-f>
        <!-- 简答题 -->
        <exercises-s-a-q
            ref="std"
            v-else-if="item.customText.exercisesType === 'SAQ'"
            :exercisesInfo="item.customText"
            :openType="2"
            :isShowRightAndAnalysis="true"
        >
        </exercises-s-a-q>
      </div>
    </el-dialog>
<!--    下方习题弹窗-->
    <el-dialog :visible.sync="exercisesShow" :show-close="true">
      <div >
        <exercises-s-c
            ref="std"
            v-if="resourceExercises.exercisesType === 'SC'"
            :exercisesInfo="resourceExercises"
            :openType="2"
            :isShowRightAndAnalysis="true"
        ></exercises-s-c>
        <exercises-m-c
            ref="std"
            v-if="resourceExercises.exercisesType === 'MC'"
            :exercisesInfo="resourceExercises"
            :openType="2"
            :isShowRightAndAnalysis="true"
        ></exercises-m-c>
        <!-- 填空题 -->
        <exercises-f-b
            ref="std"
            v-if="resourceExercises.exercisesType === 'FB'"
            :exercisesInfo="resourceExercises"
            :openType="2"
            :isShowRightAndAnalysis="true"
        >
        </exercises-f-b>
        <!-- 判断题 -->
        <exercises-t-f
            ref="std"
            v-if="resourceExercises.exercisesType=== 'TF'"
            :exercises-info="resourceExercises"
            :open-type="2"
            :isShowRightAndAnalysis="true"
        ></exercises-t-f>
        <!-- 简答题 -->
        <exercises-s-a-q
            ref="std"
            v-else-if="resourceExercises.exercisesType === 'SAQ'"
            :exercisesInfo="resourceExercises"
            :openType="2"
            :isShowRightAndAnalysis="true"
        >
        </exercises-s-a-q>
      </div>
    </el-dialog>
    <!-- 教学设计 -->
    <el-dialog :visible.sync="designWhindow" :show-close="true">
      <div class="resourceImgas" v-for="(item,index) in resourceImg" :key="index">
        <img :src="item?item:''" alt=""/>
      </div>
    </el-dialog>
<!--    个人教学设计-->
    <el-dialog :visible.sync="mydesignWhindow" :show-close="true">
      <div class="resourceImgas">
        <img :src="myresourceImg" alt=""/>
      </div>
    </el-dialog>
    <!--    ppt-->
    <div class="pptShowWhindow">
      <el-dialog :visible.sync="pptWhindow" :show-close="true" >
        <div class="coursePPtImgFlex" style="display: flex">
          <div>
            <div class="pptresourceImgas" v-for="(item,index) in pptResourceImg" :key="index">
              <img :src="item?item:''" alt="" @click="changePPtImg(item)"/>
            </div>
          </div>
          <div style="width: 80%;margin-left: 12px">
            <el-image :src="coursePPtimg" :preview-src-list="pptResourceImg"  ></el-image>
          </div>
        </div>
      </el-dialog>
    </div>
    <!--    视频播放-->
    <el-dialog
        :visible.sync="videoWhindow"
        :show-close="true"
        :append-to-body="true"
        :destroy-on-close="true">
      <div class="resourceImgas" v-if="videoWhindow">
        <vue-aliplayer-v2 ref="VueAliplayerV2" :options="videoOptions" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getResourceListApi,
  operationTopic,
  playVoucher,
  addTeacherResource,
  selectAddTeacherResourceList,
  deleteAddTeacherResourceList, audioImg,perparationResourceIsPerparation,selectLoginTeacherResourceList
} from "@/api";
import VueAliplayerV2 from 'vue-aliplayer-v2';
import {filePlayType} from "@/utils/fileUtil.js";
import ExercisesTF from "@/components/exercisesList/exercisesTF";
import ExercisesSC from "@/components/exercisesList/exercisesSC";
import ExercisesMC from "@/components/exercisesList/exercisesMC";
import ExercisesFB from "@/components/exercisesList/exercisesFB";
import ExercisesSAQ from "@/components/exercisesList/exercisesSAQ";
import documentAdministrator from "@/views/classCenter/components/documentAdministrator";
import qList from "@/views/classCenter/components/questionsList";
export default {
  components: {ExercisesTF, ExercisesSC, ExercisesMC, ExercisesFB, ExercisesSAQ,VueAliplayerV2,documentAdministrator,qList},
  data(){
    return{
      courseDetail:{},
      courseDetailArr:[],
      materialArr:[
        {id:2,name:'平台素材'},
        {id:3,name:'个人素材'},
      ],
      prepareMaterialArr:[
        {id:3,name:'教学目标'},
        {id:4,name:'教学设计'},
        {id:5,name:'电子教材'},
        {id:2,name:'作业'},
        {id:7,name:'微课'},
        {id:8,name:'动画'},
        {id:9,name:'教学视频'},
        {id:6,name:'拓展资料'},
      ],
      personageMaterialArr:[
        {id:1,name:'文件'},
        {id:2,name:'作业'},
      ],
      materialShow:0,
      materialResourceShow:0,
      intendResourceShow:0,
      teacheroptPrepareArr:[],
      coursemiCrolecture:[],
      courseDetailTotal:0,
      courseDetailPage:1,
      courseDetailSize:10,
      personageResourceShow:0,
      exercisesShow:false,
      resourceExercises:{},
      videoWhindow:false,
      pptWhindow:false,
      designWhindow:false,
      resourceImg:[],
      pptResourceImg:[],
      videoOptions: {},
      coursePPtimg:'',
      exercisesSetoutShow:false,
      teacheroptPrepareTotal:0,
      teacheroptPreparePage:1,
      teacheroptPrepareSize:9,
      teacheroptPrepareTotal1:0,
      teacheroptPreparePage1:1,
      teacheroptPrepareSize1:10,
      myresourceImg:'',
      mydesignWhindow:false,
      teacherCourse:'',
    }
  },
  methods:{
    //  获取课程详情树形结构
    getCourseDetailClassify() {
      let courseItemList = this.courseDetail.courseItemList
      const listToTree = (listArr, pid) => {
        let tree = [];
        listArr.forEach((item, index) => {
          if (item.pid === pid) {
            let child = listToTree(courseItemList, item.id)
            let data = {...item}
            child && child.length && (data['children'] = child)
            tree.push(data)
          }
        })
        return tree;
      }
      this.courseDetailArr = listToTree(courseItemList, '0');

    },
    chooseChilrden(item){
      if (!Object.keys(item).length) return;
      this.courseDetailData = item;
      this.getAddTeacherResourceList()
      if (this.materialResourceShow === 3) {
        this.getExercises();
      }else{
        this.getResourceList()
      };
      let classId=sessionStorage.getItem('classId')
      let data = {
        courseId: this.courseDetail.id,
        classId:classId
      };
      selectLoginTeacherResourceList(data).then(res => {
        let isShowIsprepareshowBtn = true;
        if(res.data && res.data.length){
          res.data.forEach((item1) => {
            if(item.id==item1.id){
              isShowIsprepareshowBtn = false;
              return;
            }
          })
        }
      })
    },
    //  查询资源列表
    getResourceList(){
      let data = {
        platformCourseId: this.courseDetailData.platformCourseId,
        platformCourseItemId: this.courseDetailData.id,
        page: this.teacheroptPreparePage1,
        size: this.teacheroptPrepareSize1,
      }
      let map = {
        0:'Objectives',
        1: 'TeacherDoc',
        2: 'Ebook',
        3: 'courseExercises',
        4: 'MicroLecture',
        5: 'Animation',
        6: 'Teaching',
        7:'Other'
      }

      data['sourceDiff'] = map[this.materialResourceShow]
      //获取章节下的资源列表
      getResourceListApi(data).then(res => {
        this.coursemiCrolecture = res.data.records;
        this.teacheroptPrepareTotal1 = JSON.parse(res.data.total);
      })
    },
    //查询教师备课列表
    getAddTeacherResourceList(){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data = {
        platformCourseId: this.courseDetailData.platformCourseId,
        platformCourseItemId: this.courseDetailData.id,
        page: this.teacheroptPreparePage,
        size: this.teacheroptPrepareSize,
        teacherId:userInfo.id
      }
      let map = {
        0:'Objectives',
        1: 'TeacherDoc',
        2: 'Ebook',
        3: 'courseExercises',
        4: 'MicroLecture',
        5: 'Animation',
        6: 'Teaching',
        7:'Other'
      }

      data['sourceDiff'] = map[this.intendResourceShow]
      selectAddTeacherResourceList(data).then(res=>{
        this.teacheroptPrepareArr=res.data.records;
        this.teacheroptPrepareArr.forEach((item,index)=>{
          if(item.customText){
            let customTextNew=JSON.parse(item.customText)
            this.$set(this.teacheroptPrepareArr[index],  "resourceName",customTextNew.exercisesTitle);
            item.customText=JSON.parse(item.customText)
          }
        })
        this.teacheroptPrepareTotal=parseInt(res.data.total)
      })
    },
    handlePrepareChange:function (teacheroptPreparePage1) {
      this.teacheroptPreparePage1 = teacheroptPreparePage1;
      this.getResourceList();
    },
    // 分页
    handleCurrentChange: function (teacheroptPreparePage) {
      this.teacheroptPreparePage = teacheroptPreparePage;
      this.getAddTeacherResourceList();
    },
    // 获取习题作业
    getExercises(){
      let query = {
        platformCourseId: this.courseDetailData.platformCourseId,
        platformCourseItemId: this.courseDetailData.id,
        page:this.courseDetailPage,
        size: this.courseDetailSize,
        privateTeacherData:0
      }
      operationTopic(query).then(res => {
        res.data.records.forEach((item, index) => {
          if(item.exercisesBody){
            item.questionOption = item.exercisesBody.split('\@')
          }
          if(item.correctAnswer){
            item.correctAnswerArr = item.correctAnswer.split(',')
          }
          if(item.exercisesType=='FB'){
            item.exercisesBody=JSON.parse(item.exercisesBody)
          }
        })
        this.coursemiCrolecture = res.data.records;
        this.courseDetailTotal=parseInt(res.data.total)
      })
    },
  //  习题作业分页
    handleExercisesChange: function (courseDetailPage) {
      this.courseDetailPage = courseDetailPage;
      this.getExercises();
    },
  //  选择平台素材还是个人
    changeMaterialTab(index){
      this.materialShow=index
    },
  //  选择资源未备课8大类
    changeMaterialresource(index){
      this.materialResourceShow=index;
      this.chooseChilrden(this.courseDetailData);
    },
  //  选择已备课资源8大类
    changeIntendResource(index){
      this.intendResourceShow=index;
      this.getAddTeacherResourceList();
    },
  //  个人素材下选择资源
    changePersonageResource(index){
      this.personageResourceShow=index;
    },
  //  查看资源弹窗
    selectResourceWindow(item){
      if(item.customText && item.sourceDiff=='courseExercises'){
        this.resourceExercises=JSON.parse(item.customText);
        this.exercisesShow=true
      }else if(item.exercisesType){
        this.resourceExercises=item
        this.exercisesShow=true
      }else if(item.resourceType===2){
        let fileType = item.resourceName?filePlayType(item.resourceName):filePlayType(item.fileOriginalName);
        if(fileType === 'avTypeAry'||fileType === 'audioTypeAry'){
          let dataArr=[]
          let data = {
            thirdPartyStorageId: item.thirdPartyStorageId
          }
          dataArr.push(data)
          audioImg({queryDataList:dataArr}).then((res)=>{
            res.data.forEach(item=>{
              this.videoOptions.playauth =item.playAuth
            })
            this.videoWhindow = true;
          })
        }
        if(fileType === 'imageTypeAry'||fileType === 'pdfTypeAry'){
          let dataArr=[]
          let data = {
            thirdPartyStorageId: item.thirdPartyStorageId
          }
          dataArr.push(data)
          audioImg({queryDataList:dataArr}).then(res => {
            res.data.forEach(item=>{
              this.myresourceImg = item.url;
            })
            this.mydesignWhindow = true;
          });

        }
        if(fileType === 'officeTypeAry'){
          let dataArr=[]
          let data = {
            thirdPartyStorageId: item.thirdPartyStorageId
          }
          dataArr.push(data)
          audioImg({queryDataList:dataArr}).then(res => {
            res.data.forEach(item=>{
              this.pptResourceImg = item.aliVideoOssUrl.split(',');
              this.coursePPtimg=this.pptResourceImg[0]
            })
          });
          this.pptWhindow = true;
        }
      }else{
        // let fileType = filePlayType(item.resourceName);
        let fileType = item.resourceName?filePlayType(item.resourceName):filePlayType(item.sourceChangeName);
        if(fileType === 'avTypeAry'||fileType === 'audioTypeAry'){
          this.videoOptions.vid = item.thirdPartyStorageId
          let data = {
            id: item.resourceId ? item.resourceId : item.id
          }
          playVoucher(data).then((res) => {
            this.videoOptions.playauth = res.data.playAuth
            this.videoWhindow = true;
          })
        }
        if(fileType === 'imageTypeAry'||fileType === 'pdfTypeAry'){
          this.designWhindow = true;
          let data = {
            id: item.resourceId ? item.resourceId : item.id
          }
          playVoucher(data).then(res => {
            this.resourceImg = res.data.playAuth.split(',')
          })
        }
        if(fileType === 'officeTypeAry'){
          this.pptWhindow = true;
          let data = {
            id: item.resourceId ? item.resourceId : item.id
          }
          playVoucher(data).then(res => {
            this.pptResourceImg = res.data.playAuth.split(',');
            this.coursePPtimg = this.pptResourceImg[0]

          })
        }
      }
    },
  //  查看资源
    selectResource(item){
      this.resourceExercises=item
      if(this.materialResourceShow === 3 || item.exercisesType){
        this.exercisesShow=true
      }else{
        const avTypeAry=["3gp","asf","avi","dat","dv","flv","f4v","gif","m2t","m3u8","m4v","mj2","mjepg","mkv","mov","mp4","mpe","mpg","mpeg","mts","ogg","qt","rm","rmvb","swf","ts","vob","wmv","webm"];
        const audioTypeAry=["aac","ac3","acm","amr","ape","caf","flac","m4a","mp3","ra","wav","wma"];
        const officeTypeAry=["docx","doc","xls","xlsx","ppt","pptx"];
        const imageTypeAry=["jpg","jpeg","png","gif","bmp","ico","raw"];
        const pdfTypeAry=["pdf"]
        let sourceFileType=item.sourceFileType
        if(avTypeAry.includes(sourceFileType)||audioTypeAry.includes(sourceFileType)){
          this.videoOptions.vid=item.thirdPartyStorageId
          let data = {
            id:item.id
          }
          playVoucher(data).then((res)=>{
            this.videoOptions.playauth = res.data.playAuth
            this.videoWhindow = true;
          })
        }
        if(imageTypeAry.includes(sourceFileType)||pdfTypeAry.includes(sourceFileType)){
          this.designWhindow = true;
          let data = {
            id: item.id
          }
          playVoucher(data).then(res => {
            this.resourceImg = res.data.playAuth.split(',')
          })
        }
        if(officeTypeAry.includes(sourceFileType)){
          this.pptWhindow = true;
          let data = {
            id: item.id
          }
          playVoucher(data).then(res => {
            this.pptResourceImg = res.data.playAuth.split(',');
            this.coursePPtimg=this.pptResourceImg[0]

          })
        }
      }
    },
    //  点击小图切换大图
    changePPtImg(item){
      this.coursePPtimg=item
    },
    unique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
    },
  //  添加备课
    addSetoutCourse(item){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      // 开关
      let flag = true;
      this.teacheroptPrepareArr.forEach(item1=>{
        if(item1.resourceId === item.id){
          flag = false;
        }
      });
      if(!flag) return this.$message.error('当前素材已添加');
      item.resourceType='1'
      this.teacheroptPrepareArr.push(item);
      let data={
        platformCourseId:item.platformCourseId,
        platformCourseItemId:item.platformCourseItemId,
        teacherId:userInfo.id,
        resourceId:item.id,
        resourceType:this.materialShow===0?'1':'2',
        resourceName:item.exercisesTitle?item.exercisesTitle:item.sourceRawName
      };
      let map = {
        0:'Objectives',
        1: 'TeacherDoc',
        2: 'Ebook',
        3: 'courseExercises',
        4: 'MicroLecture',
        5: 'Animation',
        6: 'Teaching',
        7:'Other'
      }

      data['sourceDiff'] = map[this.materialResourceShow];
      if(item.thirdPartyStorageId){
        data['thirdPartyStorageId'] =item.thirdPartyStorageId
      }
      if(item.exercisesType){
        let exercises=JSON.stringify(item)
        data['customText'] =exercises
      }
      if(item.sourceFileType){
        data['sourceFileType'] =item.sourceFileType
      }
      addTeacherResource(data).then(res=>{
        if(res.code==0){
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.getAddTeacherResourceList()
        }else{
          this.$message.error(res.msg)
        }
      })
    },
  //  删除备课
    deleteResource(item){
      var index = this.teacheroptPrepareArr.indexOf(item)
      if (index !== -1) {
        this.teacheroptPrepareArr.splice(index, 1)
      }
    },
  //  删除列表中的备课资源
    deleteResourceListIndex(item){
      this.$confirm("确认移出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data={
            id:item.id,
          }
          deleteAddTeacherResourceList(data).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "删除成功",
                type: "success",
                duration: 2000
              });
              this.getAddTeacherResourceList();
            }
          })
        })
        .catch(() => {});
    },
    //添加个人文件
    selectFeilItem(val){
      let uploadData=val[0];
      let sourceDiff=val[1];
      uploadData.resourceType=2;
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      this.teacheroptPrepareArr.push(uploadData);
      let data={
        platformCourseId: this.courseDetailData.platformCourseId,
        platformCourseItemId: this.courseDetailData.id,
        teacherId:userInfo.id,
        resourceId:uploadData.id,
        resourceType:2,
        resourceName:uploadData.fileOriginalName,
        sourceDiff:sourceDiff,
      };
      if(uploadData.thirdPartyStorageId){
        data['thirdPartyStorageId'] =uploadData.thirdPartyStorageId
      }
      addTeacherResource(data).then(res=>{
        if(res.code==0){
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.getAddTeacherResourceList()
        }else{
          this.$message.error(res.msg)
        }
      })
    },
  //  添加个人作业习题
    selectQuestionItem(val){
      let uploadData=val[0];
      let sourceDiff=val[1];
      uploadData.resourceType=2;
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      this.teacheroptPrepareArr.push(uploadData);
      let data={
        platformCourseId: this.courseDetailData.platformCourseId,
        platformCourseItemId: this.courseDetailData.id,
        teacherId:userInfo.id,
        resourceId:uploadData.id,
        resourceType:2,
        resourceName:uploadData.fileOriginalName,
        sourceDiff:sourceDiff,
        customText:uploadData
      };
      addTeacherResource(data).then(res=>{
        if(res.code==0){
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.getAddTeacherResourceList()
        }else{
          this.$message.error(res.msg)
        }
      })
    },
  //  完成备课
    publishPrepare(){
      let data={
        platformCourseItemId:this.courseDetailData.id
      };
      perparationResourceIsPerparation(data).then(res=>{
        if (res.code == 0) {
          this.$message({
            message: "备课成功",
            type: "success",
            duration: 2000
          });
          this.$router.push({path:'/classCenter'});
          sessionStorage.setItem('coursePT',"2")
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  },
  mounted() {
    let courseDetail = sessionStorage.getItem('courseDetail')
    this.courseDetail = JSON.parse(courseDetail);
    this.getCourseDetailClassify();
    if(this.$route.query.teacherCourse){
      this.materialShow=1;
      this.teacherCourse=this.$route.query.teacherCourse
    }
  },
}
</script>
<style scoped>
@import "../../../public/css/prepareCurriculum.css";
@import "../../../public/css/courseDetail.css";
@import "../../../public/css/classCenter.css";
</style>
